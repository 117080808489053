import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-contact-information',
    templateUrl: './contact-information.component.html'
})
export class ContactInformationComponent {
    @Input() public contactId: string = '';
    @Input() public contactTitle: string = '';
    @Input() public contactPhoneNumber: string = '';
    @Input() public contactMail: string = '';
}