import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/business/security.service';
import { WarningLevel } from 'src/app/framework/enums';
import { CaptchaComponent } from '../captcha/captcha.component';
declare var $: any;

@Component({
    selector: 'app-first-access',
    templateUrl: './first-access.component.html',
    styleUrls: ['./first-access.component.scss']
})
export class FirstAccessComponent {

    @ViewChild(CaptchaComponent)
    private captchaComponent: CaptchaComponent;
    public idcaptcha: string;

    constructor(
        private securityService: SecurityService,
        private messageService: MessageSwalService,
        private router: Router
    ) {
    }

    ngOnInit() {
        $('#formFirstStep').css('display', 'block');
        $('#formSecondStep').css('display', 'none');
        this.idcaptcha = "captchafa"
    }
    ngAfterViewInit(): void {
        this.addRecaptchaScript();
    }

    addRecaptchaScript() {
        window['grecaptchaCallbackfa'] = () => {
            this.captchaComponent.renderReCaptcha();
        }
       
        (function(d, s, id, obj){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallbackfa&render=explicit";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk-fa', this));
    }

    submitFirstAccess() {
        var document: string;
        var email: string;
        var emailConfirm: string;

        document = $('#firstAccessCPF').val().trim();
        email = $('#firstAccessEmail').val().trim();
        emailConfirm = $('#firstAccessEmailConfirm').val().trim();

        if (document == '') {
            this.messageService.displayValidationMessage('Preencha o CPF');
        } else if (email == '') {
            this.messageService.displayValidationMessage('Preencha o E-mail');
        } else if (emailConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de E-mail');
        } else if (email != emailConfirm) {
            this.messageService.displayValidationMessage('O e-mail e o campo de confirmação devem ser iguais');
        } else {
            this.messageService.displayTermsOfUse().then((optin) => {
                if (optin.value) {
                    this.securityService.getFirstAccessToken({
                        document: document,
                        email: email,
                        emailConfirm: emailConfirm,
                        token: '',
                        password: '',
                        passwordConfirm: '',
                        captcha: this.captchaComponent.getCaptcha()
                    }).pipe().subscribe((result) => {
                        this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                        if (result.warningLevel == WarningLevel.Success) {
                            $('#formFirstStep').css('display', 'none');
                            $('#formSecondStep').css('display', 'block');
                        }
                    });
                }
            });
        }
    }

    submitFirstAccessSecondStep() {
        var email: string;
        var token: string;
        var password: string;
        var passwordConfirm: string;

        email = $('#firstAccessEmail').val().trim();
        password = $('#firstAccessPassword').val().trim();
        passwordConfirm = $('#firstAccessPasswordConfirm').val().trim();

        // Em 14/06/2020 manutenção por Bruno Leitão a pedido da VALID: não enviar mais token de primeiro acesso

        // token = $('#firstAccessToken').val().trim();

        // if (token == '') {
        //     this.messageService.displayValidationMessage('Preencha o Token');
        // } else 

        if (password == '') {
            this.messageService.displayValidationMessage('Preencha a senha');
        } else if (passwordConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de Senha');
        } else if (password != passwordConfirm) {
            this.messageService.displayValidationMessage('A senha e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.sendFirstAccessToken({
                document: '',
                email: email,
                emailConfirm: '',
                token: token,
                password: password,
                passwordConfirm: passwordConfirm,
                captcha: ''
            }).pipe().subscribe((result) => {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                if (result.warningLevel == WarningLevel.Success) {
                    $('#formFirstStep').css('display', 'block');
                    $('#formSecondStep').css('display', 'none');
                    $('#firstAccessModal').modal('hide');
                }
            });
        }
    }
}