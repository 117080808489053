import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { APIService } from "../application/api.service";

import { ILoginResult } from "../../models/ILoginResult";

import { IChangePassword } from "../../models/IChangePassword";

import { IAppContext, IEnvelope } from "../../framework/interfaces";

import * as FW from "../../framework/core";

import { ContextService } from '../application/context.service';
import { IForgotPassword } from 'src/app/models/IForgotPassword';
import { IFirstAccess } from 'src/app/models/IFirstAccess';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    public get serviceName(): string { return "Security" };

    constructor(public context: ContextService, private api: APIService, private httpClient: HttpClient) {
    }

    //#region Authentication

    public authenticate(document: string, email: string, password: string, captcha: string): Observable<ILoginResult> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Document", document);
        validator.requestField("E-mail", email);
        validator.requestField("Senha", password);

        if (!validator.isValid) { return of(null); }

        const loginEnvelope = new FW.Envelope({
            document: document,
            email: email,
            password: password,
            captcha: captcha
        });

        return this.api.post(ctx, "/security/login/validate", loginEnvelope).
            pipe(map(this.api.getEnvelopeContent)).
            pipe(map((result: ILoginResult) => {
                if (!FW.isNull(result) && (!FW.isNullOrBlank(result.token) || !FW.isNullOrBlank(result.message))) {
                    return result;
                } else {
                    return null;
                }
            }));
    }

    public changeNewPassword(password: string, newPassword: string, newPasswordConfirm: string) {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Senha atual", password);
        validator.requestField("Nova Senha", newPassword);
        validator.requestField("Senha", newPasswordConfirm);

        if (!validator.isValid) { return of(null); }

        const changePasswordEnvelope = new FW.Envelope({
            currentPassword: password,
            newPassword: newPassword,
            newPasswordConfirm: newPasswordConfirm
        });

        return this.api.post(ctx, "/security/password/change", changePasswordEnvelope);
    }

    public setOptinDate(email: string, password: string) {
        const ctx: IAppContext = this.context.join(this.serviceName);

        const optinEnvelope = new FW.Envelope({
            email: email,
            password: password
        });

        return this.api.post(ctx, "/security/user/optin", optinEnvelope);
    }

    public recoveryPassword(entity: IForgotPassword): Observable<IEnvelope<IForgotPassword>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("CPF", entity.document);
        validator.requestField("E-mail", entity.email);
        validator.requestField("Confirme seu E-mail", entity.emailConfirm);

        if (!validator.isValid) { return of(null); }

        const postEnvelope = new FW.Envelope(entity);

        return this.api.post(ctx, '/security/password/recover', postEnvelope);
    }

    public getFirstAccessToken(entity: IFirstAccess): Observable<IEnvelope<IFirstAccess>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Document", entity.document);
        validator.requestField("E-mail", entity.email);
        validator.requestField("Confirme seu E-mail", entity.emailConfirm);

        if (!validator.isValid) { return of(null); }

        const getFirstAccessTokenEnvelope = new FW.Envelope(entity);

        return this.api.post(ctx, "/security/firstaccess/gettoken", getFirstAccessTokenEnvelope);
    }

    public sendFirstAccessToken(entity: IFirstAccess): Observable<IEnvelope<IFirstAccess>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        // Em 14/06/2020 manutenção por Bruno Leitão a pedido da VALID: não enviar mais token de primeiro acesso
        // validator.requestField("Token", entity.token);

        validator.requestField("Senha", entity.password);
        validator.requestField("Confirme sua Senha", entity.passwordConfirm);

        if (!validator.isValid) { return of(null); }

        const sendFirstAccessToken = new FW.Envelope(entity);

        return this.api.post(ctx, "/security/firstaccess/sendtoken", sendFirstAccessToken);
    }

    public sendPasswordChangeToken(entity: IFirstAccess): Observable<IEnvelope<IFirstAccess>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Token", entity.token);
        validator.requestField("Senha", entity.password);
        validator.requestField("Confirme sua Senha", entity.passwordConfirm);

        if (!validator.isValid) { return of(null); }

        const sendFirstAccessToken = new FW.Envelope(entity);

        return this.api.post(ctx, "/security/passwordchange/sendtoken", sendFirstAccessToken);
    }


    public SearchInsured(cpf): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            cpf: cpf
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/searchinsured',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ResetAccess(userId): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            userId: userId
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/resetaccess',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ExcludedAdmin(adminId): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            adminId: adminId
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/excludedadmin',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }


    public SaveAdmin(admin: any): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            id: admin.id,
            nomeUsuario: admin.nomeUsuario,
            email: admin.email,
            password: admin.password,
            numeroDocumento: admin.numeroDocumento,
            hourFinal: admin.hourFinal,
            hourInitial: admin.hourInitial,
            minFinal: admin.minFinal,
            minInitial: admin.minInitial,
            filaProcessamento: admin.filaProcessamento,
            amostra: admin.amostra,
            baseFaturamento: admin.baseFaturamento,
            baseAssinaturas: admin.baseAssinaturas,
            pesquisa: admin.pesquisa,
            operacao: admin.operacao,
            inconsistencia: admin.inconsistencia,
            faturamento: admin.faturamento,
            produtos: admin.produtos,
            manual: admin.manual,
            seguranca: admin.seguranca
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/saveadmin',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public TrySimulateAccess(CommandArgument): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            CommandArgument: CommandArgument
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/trysimulateaccess',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ChangeEmail(cpf, newEmail): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            cpf: cpf,
            newEmail: newEmail
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/changeemail',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ExportPDF(cpf): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            cpf: cpf
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/security/exportpdf',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ListAdmins(): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.api.get(ctx, '/security/listadmins');
    }
}