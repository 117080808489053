import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeFooterModule } from './controls/home-footer/home-footer.module';
import { GoToTopButtonModule } from './controls/go-to-top-button/go-to-top-button.module';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ForgotPasswordModule } from './controls/forgot-password/forgot-password.module';
import { FirstAccessModule } from './controls/first-access/first-access.module';
import { AppRoutingModule } from './app.routing.module';
import { ProductsModule } from './pages/products/products.module';
import { AdminModule } from './pages/admin/admin.module';
import { BillingModule } from './pages/billing/billing.module';
import { BillingBaseModule } from './pages/billing-base/billing-base.module';
import { SignBaseModule } from './pages/sign-base/sign-base.module';
import { SampleModule } from './pages/sample/sample.module';
import { SampleTemplateModule } from './pages/sample-template/sample-template.module';
import { ManagerModule } from './pages/manager/manager.module';
import { HomeModule } from './pages/home/home.module';
import { LoginModule } from './pages/login/login.module';
import { DocumentsModule } from './pages/documents/documents.module';
import { serviceProviders } from './services/serviceprovider';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors/interceptorprovider';
import { DirectivesModule } from './directives/directives.module';
import { CaptchaModule } from './controls/captcha/captcha.module';
import { SocialModule } from './controls/social/social.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SummaryModule } from './pages/summary/summary.module';
import { ManualModule } from './pages/manual/manual.module';
import { SearchModule } from './pages/search/search.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ProductsAdminModule } from './pages/products-admin/products-admin.module';
import { InconsistencyModule } from './pages/Inconsistency/Inconsistency.module';
import { ManagerProductsModule } from './pages/manager-products/manager-products.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SampleDataModule } from './pages/sample-data/sample-data.module';
import { RouteFirstAccessModule } from './pages/route-first-access/route-first-access.module';
import { RouteForgotPasswordModule } from './pages/route-forgot-password/route-forgot-password.module';
import { AlterPasswordModule } from './pages/alter-password/alter-password.module';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HomeModule,
    RouteFirstAccessModule,
    RouteForgotPasswordModule,
    LoginModule,
    FormsModule,
    HomeFooterModule,
    AngularEditorModule,
    GoToTopButtonModule,
    AnimateOnScrollModule.forRoot(),
    ForgotPasswordModule,
    FirstAccessModule,
    AppRoutingModule,
    ProductsModule,
    DocumentsModule,
    AlterPasswordModule,
    HttpClientModule,
    DirectivesModule,
    CaptchaModule,
    SocialModule,
    AdminModule,
    BillingModule,
    BillingBaseModule,
    SignBaseModule,
    InconsistencyModule,
    SummaryModule,
    SearchModule,
    SampleDataModule,
    SampleTemplateModule,
    SampleModule,
    ManualModule,
    ManagerProductsModule,
    ManagerModule,
    ProductsAdminModule,
    TranslateModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [httpInterceptorProviders, serviceProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }