import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[app-block-copy-paste]'
})
export class BlockCopyPasteDirective {
  constructor() { }

  @HostListener('paste', ['$event']) blockPaste(e) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e) {
    e.preventDefault();
  }
}