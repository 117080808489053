import { NgModule } from '@angular/core';
import { ManagerProductsComponent } from './manager-products.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
    declarations: [ManagerProductsComponent],
    imports: [HeaderModule, BrowserModule, CommonModule, FormsModule, AngularEditorModule, HttpClientModule],
    exports: [ManagerProductsComponent],
    providers: [],
    bootstrap: [ManagerProductsComponent]
})
export class ManagerProductsModule { }