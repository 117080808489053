import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { SecurityService } from 'src/app/services/business/security.service';
import { ContextService } from 'src/app/services/application/context.service';
import * as FW from "src/app/framework/core";
import { CaptchaComponent } from 'src/app/controls/captcha/captcha.component';
import { TranslateService } from '@ngx-translate/core';
import { ILoginResult } from 'src/app/models/ILoginResult';
declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    providers: [
        CaptchaComponent
    ]
})
export class LoginComponent implements OnInit, AfterViewInit {

    @ViewChild(CaptchaComponent)
    private captchaComponent: CaptchaComponent;

    public sourceImgH: String = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
    public idcaptcha: string;

    constructor(
        private router: Router,
        private context: ContextService,
        private messageService: MessageSwalService,
        private securityService: SecurityService,
        private translateService: TranslateService) {

        this.translateService.setTranslation('pt', { "User does not exist or password is incorrect.": 'Usuário não existe ou senha incorreta.' }, true);
        this.translateService.setTranslation('pt', { "The user is blocked due a 5 invalid login attempts.": 'O usuário está bloqueado devido a 5 tentativas inválidas de login.' }, true);
    }
    ngOnInit(): void {

        $('body').css("background-color", "var(--background-color-white)");
        this.idcaptcha = "captchaLogin";
        this.addRecaptchaScript();
        this.checkDarkMode();
    }
    ngAfterViewInit(): void {
        this.definePlaceHolderText(window.innerWidth);
        this.displayMessageModal();
    }

    displayMessageModal(): void {
        let modalElement: any = $('#modal-aviso');
        if (modalElement) {
            
            modalElement.on('shown.bs.modal', () => {
                let countdownTime = 15;

                // Função para atualizar a contagem regressiva
                const countdownElement = document.getElementById('countdown');
                const countdownInterval = setInterval(() => {
                    countdownTime--;
                    countdownElement.textContent = countdownTime.toString();

                    // Se o tempo chegar a 0, redirecionar o usuário
                    if (countdownTime === 1) {
                        clearInterval(countdownInterval);
                        window.location.href = 'https://wwws.bradescoseguros.com.br/portalprotecao';
                    }
                }, 1000); // Executa a função a cada 1 segundo (1000 milissegundos)
            });

            modalElement.modal('toggle');
        }
    }

    checkDarkMode() {
        if (document.documentElement.getAttribute("data-theme") == 'dark') {
            this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_WHITE_H.svg"
        } else {
            this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
        }
    }

    addRecaptchaScript() {
        if (window['grecaptcha'] == undefined || window['grecaptcha'] == null) {
            window['grecaptchaCallback'] = () => {
                this.captchaComponent.renderReCaptcha();
            }
        } else {
            setTimeout(() => {
                this.captchaComponent.renderReCaptcha();
            }, 100);
        }

        (function (d, s, id, obj) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk', this));
    }

    goToHome() {
        this.router.navigate(['/']);
    }

    goToFirstAccess() {
        this.router.navigate(['/firstaccess']);
    }

    goToForgotPassword() {
        this.router.navigate(['/forgotpassword']);
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: "smooth" });
    }

    onResize(event) {
        this.definePlaceHolderText(event.target.innerWidth);
    }

    definePlaceHolderText(width: number) {
        if (width <= 575) {
            $('#login').attr("placeholder", "123.456.789-00");
            $('#email').attr("placeholder", "nome@email.com");
            $('#loginPassword').attr("placeholder", "Digite a sua senha");
        }
        else {
            $('#login').attr("placeholder", "Seu CPF");
            $('#email').attr("placeholder", "Seu email");
            $('#loginPassword').attr("placeholder", "Sua senha");
        }
    }

    openUrl(event, url) {
        if (event.key == "Enter") {
            window.location.href = url;
        }
    }
    
    keyGoHome(event) {
        if (event.key == 'Enter')
            this.goToHome();
    }
    submitLogin() {
        var document = $('#login').val().trim();
        var email = $('#email').val().trim();
        var password = $('#loginPassword').val().trim();
        var captcha = this.captchaComponent.getCaptcha();

        if (document == '') {
            this.messageService.displayValidationMessage('Preencha o CPF');
        } else if (email == '') {
            this.messageService.displayValidationMessage('Preencha o E-mail');
        } else if (password == '') {
            this.messageService.displayValidationMessage('Preencha a Senha');
        } else if (captcha == null || captcha == undefined ||
            captcha == '') {
            this.messageService.displayValidationMessage('Preencha o Recaptcha');
        } else {
            this.securityService.authenticate(document, email, password, captcha)
                .subscribe(
                    (loginResult) => {
                        if (!FW.isNull(loginResult) && !FW.isNullOrBlank(loginResult.token)) {

                            sessionStorage.removeItem("hide_privacy_disclaimer");

                            if (!FW.isNullOrBlank(loginResult.optinDate)) {
                                this.goToUserProducts(loginResult);
                            } else {
                                this.messageService.displayTermsOfUse().
                                    then((optin) => {
                                        if (optin.value) {
                                            this.securityService.setOptinDate(email, password).subscribe(() => {
                                                this.goToUserProducts(loginResult);
                                            });
                                        }
                                    });
                            }
                        } else {
                            if (!FW.isNullOrBlank(loginResult.message)) {
                                if (loginResult.message == 'User does not exist or password is incorrect.')
                                    loginResult.message = 'Usuário não existe ou senha incorreta.';
                                if (loginResult.message == 'The user is blocked due a 5 invalid login attempts.')
                                    loginResult.message = 'O usuário está bloqueado devido a 5 tentativas inválidas de login.';
                                this.messageService.displayMessage({ warninglevel: loginResult.warningLevel, message: loginResult.message });
                            }
                            else
                                this.messageService.displayMessage({ warninglevel: 2, message: 'Erro ao autenticar usuário' });
                        }
                    },
                    (error) => {
                        this.messageService.displayMessage({ warninglevel: 2, message: 'Erro ao autenticar usuário' });
                    }
                );
        }
    }

    private goToUserProducts(loginResult: ILoginResult): void {
        this.context.authService.initSession(
            loginResult.token,
            (new Date(loginResult.timeout)),
            loginResult.id,
            loginResult.name,
            loginResult.email,
            loginResult.privilege,
            loginResult.role,
            loginResult.uniqueNumber,
            loginResult.admin,
            loginResult.perfilAdmin);

        this.router.navigate(['/products']);
    }
}