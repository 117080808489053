import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as FW from "src/app/framework/core";

@Injectable({
    providedIn: 'root'
})
export class CaptchaService {
    constructor(private httpClient: HttpClient) {
    }

    public generateCaptcha() {
        return this.httpClient.get<FW.Envelope<string>>(environment.baseApiUrl + '/security/captcha/generate');
    }
}