import { NgModule } from '@angular/core';
import { SampleComponent } from './sample.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [SampleComponent],
    imports: [HeaderModule, CommonModule, FormsModule],
    exports: [SampleComponent],
    providers: [],
    bootstrap: [SampleComponent]
})
export class SampleModule { }