import { NgModule } from '@angular/core';
import { SampleTemplateComponent } from './sample-template.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [SampleTemplateComponent],
    imports: [HeaderModule, CommonModule, FormsModule],
    exports: [SampleTemplateComponent],
    providers: [],
    bootstrap: [SampleTemplateComponent]
})
export class SampleTemplateModule { }