import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { IProduct } from 'src/app/models/IProduct';
import { ProductService } from 'src/app/services/business/product.service';
import { DocumentService } from 'src/app/services/business/document.service';
import { IDocument } from 'src/app/models/IDocument';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';

declare var $: any;

@Component({
    selector: 'app-sample',
    templateUrl: './sample.component.html'
})
export class SampleComponent implements OnInit {
    public tableData: any;
    public bases: IProduct[] = [];
    public products: IProduct[] = [];
    public documents: IDocument[] = [];
    public reportType: number;
    public selectedBase: number = null;
    public selectedProduct: number = null;
    public documentUrl: any;
    constructor(private adminService: AdminService,
        private productService: ProductService,
        private documentService: DocumentService,
        private messageService: MessageSwalService) {
    }

    ngOnInit(): void {
        this.loadBases();
    }

    loadBases() {
        this.productService.ListBase().subscribe((result: any) => {
            if (result != null) {
                this.bases = result.content;
            }
        });
    }


    loadProduct() {
        this.selectedProduct = null;
        this.documents = [];
        this.productService.ListProductByBase(this.selectedBase).subscribe((result: any) => {
            if (result != null) {
                this.products = result.content;
                if (this.products.length == 0)
                    this.messageService.displayValidationMessage('Nenhum produto encontrado');
            }
        });
    }

    searchSample() {
        this.documents = [];
        this.documentService.ListDocumentSampleProduct(this.selectedBase, this.selectedProduct).subscribe((result: any) => {
            if (result != null) {
                this.documents = result.content;

                console.log(this.documents);

                if (this.documents.length == 0)
                    this.messageService.displayValidationMessage('Nenhum documento encontrado');
            }
        });
    }

    download(doc: IDocument) {
        this.documentService.GenerateUrlShowDocumentAdmin(doc.id).subscribe((result: any) => {
            this.documentUrl = result;
            var element = document.createElement('a');
            element.setAttribute('href', this.documentUrl);
            element.setAttribute('target', "_blank");
            element.setAttribute('download', doc.nomeExibicao + ".pdf");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });

    }
}