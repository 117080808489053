import { NgModule } from '@angular/core';
import { HomeFooterComponent } from './home-footer.component';
import { ContactInformationModule } from '../contact-information/contact-information.module';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

@NgModule({
    declarations: [
        HomeFooterComponent
    ],
    imports: [
        ContactInformationModule,
        AnimateOnScrollModule.forRoot()
    ],
    exports: [HomeFooterComponent],
    providers: [],
    bootstrap: [HomeFooterComponent]
})
export class HomeFooterModule {}