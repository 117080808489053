import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [AdminComponent],
    imports: [HeaderModule, CommonModule],
    exports: [AdminComponent],
    providers: [],
    bootstrap: [AdminComponent]
})
export class AdminModule { }