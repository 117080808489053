import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { ICompany } from 'src/app/models/ICompany';
import { CompanyService } from 'src/app/services/business/company.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { ProductService } from 'src/app/services/business/product.service';


declare var $: any;

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html'
})
export class SummaryComponent implements OnInit {
    public tableData: any;
    public codOperation: any;
    public selectedProduct: any;
    public operations: any[] = [];

    constructor(private adminService: AdminService,
        private companyService: CompanyService,
        private messageService: MessageSwalService,
        private productService: ProductService) {
    }

    ngOnInit(): void {
    }

    searchSummary() {
        this.operations = [];
        this.productService.SearchOperationSummary(this.codOperation, this.selectedProduct).subscribe((result: any) => {
            if (result != null) {
                this.operations = result.content;

                console.log(this.operations);

                if (this.operations.length == 0)
                    this.messageService.displayValidationMessage('Nenhum dado de resumo de operação');
            }
        });
    }


}