import { NgModule } from '@angular/core';
import { ProductsAdminComponent } from './products-admin.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { ProductModule } from 'src/app/controls/product/product.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ProductsAdminComponent],
    imports: [HeaderModule, ProductModule, CommonModule],
    exports: [ProductsAdminComponent],
    providers: [],
    bootstrap: [ProductsAdminComponent]
})
export class ProductsAdminModule { }