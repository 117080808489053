import { NgModule } from '@angular/core';
import { AlterPasswordComponent } from './alter-password.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { ProductModule } from 'src/app/controls/product/product.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { HomeFooterModule } from 'src/app/controls/home-footer/home-footer.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [AlterPasswordComponent],
    imports: [
        HeaderModule, 
        ProductModule, 
        DirectivesModule,
        CommonModule, 
        HomeFooterModule],
    exports: [AlterPasswordComponent],
    providers: [],
    bootstrap: [AlterPasswordComponent]
})
export class AlterPasswordModule {}