import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[app-cpf-mask]'
})
export class CPFMaskDirective {
    constructor() { }

    @HostListener('change', ['$event'])
    onChange(event) {
        this.onInputChange(event);
    }

    @HostListener('keyup', ['$event'])
    keyUp(event) {
        this.onInputChange(event);
    }

    onInputChange(event) {
        let newVal = event.srcElement.value.replace(/\D/g, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
        } else if (newVal.length <= 9) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
        } else {
            newVal = newVal.substring(0, 11);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');            
        }

        event.srcElement.value = newVal;
    }
}