import { Injectable } from '@angular/core';

declare var Pace: any;
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    constructor() {
    }

    public displayLoader(): void {
        // Pace.start();
        $('app-root').prepend('<div class="loader" style="display: none;"><div class="bs-loading"><div class="bs-loader">Loading...</div><p>Carregando..</p></div></div>');
        $('.loader').fadeIn();
    }

    public hideLoader() {
        // Pace.stop();
        $('.loader').fadeOut(0);
        $('.loader').remove();
    }
}