import { Injectable } from '@angular/core';
import { ContextService } from '../application/context.service';
import { APIService } from '../application/api.service';
import { IAppContext } from 'src/app/framework/interfaces';
import * as FW from "../../framework/core";
import { WarningLevel } from 'src/app/framework/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BillingService {
    public get serviceName(): string { return "Billing" };

    constructor(public context: ContextService, private api: APIService,
        private httpClient: HttpClient) {
    }

    public reportCsv(reportType, selectedCompany): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            reportType: reportType,
            selectedCompany: selectedCompany
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/billing/reportcsv',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public signBaseCsv(startDate, endDate): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            startDate: startDate,
            endDate: endDate
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/billing/signbasecsv',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    
    public billingBaseCsv(year, month): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            year: year,
            month: month
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/billing/billingbasecsv',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }
}
