import { Injectable } from '@angular/core';
import { ContextService } from '../application/context.service';
import { APIService } from '../application/api.service';
import { IDocument } from 'src/app/models/IDocument';
import { IAppContext } from 'src/app/framework/interfaces';
import * as FW from "../../framework/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Storage } from "../../framework/storage";

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    public get serviceName(): string { return "Document" };

    constructor(public context: ContextService, private httpClient: HttpClient, private api: APIService, private sanitizer: DomSanitizer) {
    }

    public listDocuments(productId, numeroUnicoDocumento, chaveSeguranca): Observable<FW.Envelope<IDocument[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            productId: productId,
            numeroUnicoDocumento: numeroUnicoDocumento,
            email: this.context.authService.userEmail,
            chaveSeguranca: chaveSeguranca
        });

        return this.httpClient.post<FW.Envelope<IDocument[]>>(
            environment.baseApiUrl + '/document/listbyproduct',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public GetManualByProduct(codProduct, codBase): Observable<FW.Envelope<IDocument[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            codProduct: codProduct,
            codBase: codBase
        });

        return this.httpClient.post<FW.Envelope<IDocument[]>>(
            environment.baseApiUrl + '/document/getmanualbyproduct',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ChangeManual(manualId, filecontent): Observable<FW.Envelope<IDocument[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            manualId: manualId,
            filecontent: filecontent
        });

        return this.httpClient.post<FW.Envelope<IDocument[]>>(
            environment.baseApiUrl + '/document/changemanual',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public showDocument(documentId): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(environment.baseApiUrl + '/document/show/' + documentId + '/' + this.context.authService.userGUID);
    }
    public getDowloadDocumentUrl(documentId): string {
        return environment.baseApiUrl + '/document/show/' + documentId + '/' + this.context.authService.userGUID;
    }

    public GenerateUrlShowDocumentAdmin(documentid): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            documentid: documentid,
            url: environment.baseApiUrl + '/document/showadmin/'
        });

        return this.httpClient.post<FW.Envelope<IDocument[]>>(
            environment.baseApiUrl + '/document/generateurlshowadmin',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ListDocumentSampleProduct(codBase, codProduct): Observable<FW.Envelope<IDocument[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            codBase: codBase,
            codProduct: codProduct
        });

        return this.httpClient.post<FW.Envelope<IDocument[]>>(
            environment.baseApiUrl + '/document/listdocumentsampleproduct',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }
}