import { NgModule } from '@angular/core';
import { ContactInformationComponent } from './contact-information.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ContactInformationComponent],
    imports: [CommonModule],
    exports: [ContactInformationComponent],
    providers: [],
    bootstrap: [ContactInformationComponent]
})
export class ContactInformationModule {

}