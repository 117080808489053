import { Injectable } from '@angular/core';
import { ContextService } from '../application/context.service';
import { APIService } from '../application/api.service';
import { IAppContext } from 'src/app/framework/interfaces';
import * as FW from "../../framework/core";
import { WarningLevel } from 'src/app/framework/enums';
import { Observable } from 'rxjs';
import { IProduct } from 'src/app/models/IProduct';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IDocument } from 'src/app/models/IDocument';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    public get serviceName(): string { return "Product" };

    constructor(public context: ContextService, private api: APIService, private httpClient: HttpClient) {
    }

    public listProducts(userId?: any): Observable<FW.Envelope<IProduct[]>> {
        //public listProducts(): IProduct[] {
        const ctx: IAppContext = this.context.join(this.serviceName);
        var Url = '/product/listbyuser';
        const postEnvelope = new FW.Envelope({
            id: 0,
            title: '',
            description: '',
            icon: ''
        });
        if (userId != null) {
            Url += '?userId=' + userId;
        }
        // var productList: IProduct[];
        // productList = [
        //     {
        //         id: 1,
        //         classeDoIcone: 'far fa-user',
        //         nome: 'proteção pessoal havan',
        //         descricao: 'mais segurança e bem estar para você e sua família em momentos delicados como morte acidental e invalidez permanente, total ou parcial.'
        //     },
        //     {
        //         id: 2,
        //         classeDoIcone: 'fal fa-credit-card',
        //         nome: 'bilhete de seguro proteção financeira havan',
        //         descricao: 'um seguro que cobre seus gastos com cartão em caso de morte e invalidez permanente total por acidente ou perda involuntária de emprego e incapacidade física total temporária.'
        //     },
        //     {
        //         id: 3,
        //         classeDoIcone: 'fal fa-heart',
        //         nome: 'proteção diária havan',
        //         descricao: 'em caso de internação hospitalar decorrente de acidente ou doença, você conta com renda extra por dia de internação para ser usada com gastos inesperados.'
        //     }
        // ];

        // return productList;

        return this.api.get(ctx, Url);
    }

    public listProductsNoKey(userId?: any): Observable<FW.Envelope<IProduct[]>> {
        //public listProducts(): IProduct[] {
        const ctx: IAppContext = this.context.join(this.serviceName);
        var Url = '/product/listbyusernokey';
        const postEnvelope = new FW.Envelope({
            id: 0,
            title: '',
            description: '',
            icon: ''
        });
        if (userId != null) {
            Url += '?userId=' + userId;
        }
        // var productList: IProduct[];
        // productList = [
        //     {
        //         id: 1,
        //         classeDoIcone: 'far fa-user',
        //         nome: 'proteção pessoal havan',
        //         descricao: 'mais segurança e bem estar para você e sua família em momentos delicados como morte acidental e invalidez permanente, total ou parcial.'
        //     },
        //     {
        //         id: 2,
        //         classeDoIcone: 'fal fa-credit-card',
        //         nome: 'bilhete de seguro proteção financeira havan',
        //         descricao: 'um seguro que cobre seus gastos com cartão em caso de morte e invalidez permanente total por acidente ou perda involuntária de emprego e incapacidade física total temporária.'
        //     },
        //     {
        //         id: 3,
        //         classeDoIcone: 'fal fa-heart',
        //         nome: 'proteção diária havan',
        //         descricao: 'em caso de internação hospitalar decorrente de acidente ou doença, você conta com renda extra por dia de internação para ser usada com gastos inesperados.'
        //     }
        // ];

        // return productList;

        return this.api.get(ctx, Url);
    }

    public ListBase(): Observable<FW.Envelope<IProduct[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.api.get(ctx, '/product/listbase');
    }

    public SaveProduct(productid, active, htmlcontent): Observable<FW.Envelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            productid: productid,
            active: active,
            htmlcontent: htmlcontent
        });

        return this.httpClient.post<FW.Envelope<any>>(
            environment.baseApiUrl + '/product/saveproduct',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ListTemplateSampleProduct(codBase, codProduct): Observable<FW.Envelope<IDocument[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            codBase: codBase,
            codProduct: codProduct
        });

        return this.httpClient.post<FW.Envelope<IDocument[]>>(
            environment.baseApiUrl + '/product/listtemplatesampleproduct',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public ListProductByBase(codBase): Observable<FW.Envelope<IProduct[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            codBase: codBase
        });

        return this.httpClient.post<FW.Envelope<IProduct[]>>(
            environment.baseApiUrl + '/product/listproductbybase',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    public SearchOperationSummary(operacao, codigoProduto): Observable<FW.Envelope<IProduct[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            operacao: operacao,
            codigoProduto: codigoProduto
        });

        return this.httpClient.post<FW.Envelope<IProduct[]>>(
            environment.baseApiUrl + '/product/searchoperationsummary',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }
}