import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { ProductModule } from 'src/app/controls/product/product.module';
import { HomeFooterModule } from 'src/app/controls/home-footer/home-footer.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ProductsComponent],
    imports: [
        HeaderModule, 
        ProductModule, 
        CommonModule, 
        HomeFooterModule],
    exports: [ProductsComponent],
    providers: [],
    bootstrap: [ProductsComponent]
})
export class ProductsModule {}