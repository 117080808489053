import { Injectable } from '@angular/core';
import { ContextService } from '../application/context.service';
import { APIService } from '../application/api.service';
import { IDocument } from 'src/app/models/IDocument';
import { IAppContext } from 'src/app/framework/interfaces';
import * as FW from "../../framework/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Storage } from "../../framework/storage";
import { IRegistroImportacao } from 'src/app/models/IRegistroImportacao';

@Injectable({
    providedIn: 'root'
})
export class RegistroImportacaoService {
    public get serviceName(): string { return "RegistroImportacao" };

    constructor(public context: ContextService, private httpClient: HttpClient, private api: APIService, private sanitizer: DomSanitizer) {
    }

    public ListDataSampleProduct(codBase, codProduct): Observable<FW.Envelope<IRegistroImportacao>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const postEnvelope = new FW.Envelope({
            codBase: codBase,
            codProduct: codProduct
        });

        return this.httpClient.post<FW.Envelope<IRegistroImportacao>>(
            environment.baseApiUrl + '/registroimportacao/listdatasampleproduct',
            postEnvelope,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }
}