import { NgModule } from '@angular/core';
import { FirstAccessComponent } from './first-access.component';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CaptchaModule } from '../captcha/captcha.module';
import { SocialModule } from '../social/social.module';

@NgModule({
    declarations: [FirstAccessComponent],
    imports: [RouterModule, DirectivesModule, CaptchaModule, SocialModule],
    exports: [FirstAccessComponent],
    providers: [],
    bootstrap: [FirstAccessComponent]
})
export class FirstAccessModule {}