import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialComponent } from '../../controls/social/social.component';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements AfterViewInit, OnInit {
    @ViewChild(SocialComponent)
    public socialComponent: SocialComponent;
    private mobileMenuVisible: boolean = false;

    constructor(
        private router: Router,
        private translateService: TranslateService) {
        this.translateService.setTranslation('pt', { "User does not exist or password is incorrect.": 'Usuário não existe ou senha incorreta.' }, true);
        this.translateService.setTranslation('pt', { "The user is blocked due a 5 invalid login attempts.": 'O usuário está bloqueado devido a 5 tentativas inválidas de login.' }, true);
    }

    ngOnInit() {
        this.socialComponent.checkDarkMode();
        $('body').css("background-color", "var(--background-color-offset)");
    }

    ngAfterViewInit(): void {
        this.displayMessageModal();
    }

    displayMessageModal(): void {
        let modalElement: any = $('#modal-aviso');
        if (modalElement) {
            
            modalElement.on('shown.bs.modal', () => {
                let countdownTime = 15;

                // Função para atualizar a contagem regressiva
                const countdownElement = document.getElementById('countdown');
                const countdownInterval = setInterval(() => {
                    countdownTime--;
                    countdownElement.textContent = countdownTime.toString();

                    // Se o tempo chegar a 0, redirecionar o usuário
                    if (countdownTime === 1) {
                        clearInterval(countdownInterval);
                        window.location.href = 'https://wwws.bradescoseguros.com.br/portalprotecao';
                    }
                }, 1000); // Executa a função a cada 1 segundo (1000 milissegundos)
            });

            modalElement.modal('toggle');
        }
    }

    lowVision() {
        this.socialComponent.lowVision();
    }

    changeFontSize(value: number) {
        this.socialComponent.changeFontSize(value);
    }

    mobileMenu() {
        if (this.mobileMenuVisible) {
            this.mobileMenuVisible = false;
        } else {
            this.mobileMenuVisible = true;
        }
        if (this.mobileMenuVisible) {
            $(".mobile-menu").removeClass('hide');
            $(".mobile-menu").fadeIn('slow');

            $(".actionable").addClass('card-icon-menu-actionable-active');
        } else {
            $(".mobile-menu").addClass('hide');

            $(".actionable").removeClass('card-icon-menu-actionable-active');
        }
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: "smooth" });
    }

    keyScroll(event, el: HTMLElement) {
        if (event.key == 'Enter')
            el.scrollIntoView({ behavior: "smooth" });
    }

    keygoToLogin(event) {
        if (event.key == 'Enter')
            this.goToLogin();
    }
    goToLogin() {
        this.router.navigate(['/login']);
    }
    keyGoHome(event) {
        if (event.key == 'Enter')
            this.goToHome();
    }
    goToHome() {
        this.router.navigate(['/']);
    }
    openAccord(event) {
        debugger;
        if (event.key == 'Enter')
            $(event.target).click();
    }
}