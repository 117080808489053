import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { ICompany } from 'src/app/models/ICompany';
import { CompanyService } from 'src/app/services/business/company.service';
import { BillingService } from 'src/app/services/business/billing.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';


declare var $: any;

@Component({
    selector: 'app-sign-base',
    templateUrl: './sign-base.component.html'
})
export class SignBaseComponent implements OnInit {
    public tableData: any;
    public companies: ICompany[];
    public startDate: any;
    public endDate: any;

    constructor(private adminService: AdminService,
        private companyService: CompanyService,
        private billingSerivce: BillingService,
        private messageService: MessageSwalService) {
    }

    ngOnInit(): void {
    }

    searchReport() {
        this.billingSerivce.signBaseCsv(this.startDate, this.endDate).subscribe((result: any) => {
            if (result != null) {
                if (result.content != null) {
                    let dateTime = new Date()
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/csv;base64,' + result.content);
                    element.setAttribute('download', "BASE_ASSINATURAS_" + dateTime.toJSON() + ".csv");
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    return;
                }
            }
            this.messageService.displayValidationMessage('Sem assinaturas nesse período');
        });
    }
}