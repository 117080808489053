import { MessageService } from './application/message.service';
import { SecurityService } from './business/security.service';
import { APIService } from './application/api.service';
import { ContextService } from './application/context.service';
import { HttpErrorHandler } from './application/httperrorhandler.service';
import { LoaderService } from './application/loader.service';
import { HighContrastService } from './application/highContrast.service';

export const serviceProviders = [
    APIService,
    ContextService,
    HttpErrorHandler,
    MessageService,
    SecurityService,
    LoaderService,
    HighContrastService
];