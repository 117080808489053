import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/business/product.service';
import { CommonModule } from '@angular/common';
import { IProduct } from 'src/app/models/IProduct';
import * as FW from "../../framework/core";
import { ActivatedRoute } from '@angular/router';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';

@Component({
    selector: 'app-products-admin',
    templateUrl: './products-admin.component.html'
})
export class ProductsAdminComponent implements OnInit {
    @Input() productList: IProduct[];
    public chaveSeguranca: any = null;
    constructor(private productService: ProductService,
        private messageService: MessageSwalService,
        private route: ActivatedRoute) {
        this.chaveSeguranca = this.route.snapshot.paramMap.get('id')
    }

    public hidePrivacyDisclaimer: boolean = false;

    ngOnInit(): void {
        this.hidePrivacyDisclaimer = (sessionStorage.getItem("hide_privacy_disclaimer") || "0") == "1";

        this.productService.listProductsNoKey(this.route.snapshot.paramMap.get('id')).subscribe((resp) => {
            this.productList = resp.content;
            if (this.productList.length == 0)
                this.messageService.displayMessage({ warninglevel: 2, message: "Nenhum produto encontrado." });
        }, (error) => {
            this.messageService.displayMessage({ warninglevel: 2, message: "Usuário não autorizado." });
        });
    }

    public dismissPrivacyDisclaimer(): void {
        this.hidePrivacyDisclaimer = true;
        sessionStorage.setItem("hide_privacy_disclaimer", "1");
    }
}