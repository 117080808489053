import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { ContextService } from "./context.service";
declare var $: any;
@Injectable({
    providedIn: 'root',
})
export class RouteGuardService implements CanActivate {

    constructor(private context: ContextService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const hasAuthData: boolean = this.context.authService.tryToRestoreSession();
        $(window).scrollTop({ top: 0, behavior: 'smooth' });
        const redirectToUrl: string = state.url;

        if (hasAuthData) {
            if (!this.context.authService.isSessionActive) {
                console.log("Session expired!");
                this.context.navigation.changeRoute("/", { redirectTo: redirectToUrl });
                return false;
            }
        } else {
            console.log("Authentication required!");
            this.context.navigation.changeRoute("/", { redirectTo: redirectToUrl });
            return false;
        }

        return true;
    }
}