import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HighContrastService } from 'src/app/services/application/highContrast.service';

declare var $: any;

export let browserRefresh = false;

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html'
})

export class SocialComponent implements OnInit {
  @Input() public affectedElements: HTMLElement[];

  public direction: number = 0;
  public sourceImgH: String = "assets/img/Bra_SEGUROS_RGB_RED_H.svg";
  public sourceImgV: String = "assets/img/BRADESCO_SEGUROS_RGB_RED_V.svg";
  public theme: String = document.documentElement.getAttribute("data-theme");

  constructor(
    private highContrastService: HighContrastService
  ) {
  }

  ngOnInit(): void {
    this.affectedElements = $("p, b, span, em, h1, h2, h3, h4, h5, h6, input, .zoom-text"); // Can be extended, ex. $("div, p, span.someClass")
  }

  keyChangeFontSize(value: number, event) { 
    if(event.key == "Enter"){
      this.changeFontSize(value);
    }    
  }

  changeFontSize(value: number) {
    this.affectedElements = $("p, b, span, em, h1, h2, h3, h4, h5, h6, input, .zoom-text"); // Can be extended, ex. $("div, p, span.someClass")
    var isAllowed = this.direction + value >= -2 && this.direction + value <= 2;
    this.direction = isAllowed ? this.direction + value : this.direction;

    if (isAllowed) {
      Array.from(this.affectedElements).forEach(element => {
        let currentItem = $(element);
        let newValue = parseInt(currentItem.css("font-size")) + value;
        let transform = currentItem.css('text-transform');
        currentItem.css('font-size', '');
        currentItem.attr("style", function (i, s) { return (s || '') + "font-size: " + newValue + "px !important;" });
        currentItem.css('text-transform', transform);
      });
    }
  }

  onResize(event) {
    this.direction = 0;
    Array.from(this.affectedElements).forEach(element => {
      let currentItem = $(element);
      currentItem.css('font-size', '');
      currentItem.attr("style", function (i, s) { return (s || '') });
    });
  }

  openUrl(event, url){
    if(event.key == "Enter"){
      window.location.href = url;
    }
  }

  checkDarkMode() {
    if (document.documentElement.getAttribute("data-theme") == 'dark') {
      this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_WHITE_H.svg"
      this.sourceImgV = "assets/img/BRADESCO_SEGUROS_RGB_WHITE_V.svg"
    } else {
      this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
      this.sourceImgV = "assets/img/BRADESCO_SEGUROS_RGB_RED_V.svg"
    }
  }
  
  keyLowVision(event){
    if(event.key == "Enter"){
      this.lowVision();
    }
  }

  lowVision() {
    //local storage is used to override OS theme settings
    if (!window.matchMedia) {
      //matchMedia method not supported
      return false;
    } else if (document.documentElement.getAttribute("data-theme") == "dark") {
      this.theme = "light";
    } else {
      this.theme = "dark";
    }
    // } else if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //   //OS theme setting detected as dark
    //   this.theme = "dark";

    //dark theme preferred, set document with a `data-theme` attribute
    if (this.theme == "dark") {
      document.documentElement.setAttribute('data-theme', "dark");
    } else {
      document.documentElement.removeAttribute('data-theme');
    }

    this.checkDarkMode();

    this.highContrastService.setHighContrast();
    // this.feedback();
  }

  // feedback() {
  //   this.darkModeUpdate.emit(this.sourceImgV.toString());
  // }

  lowHearing() {
  }

}