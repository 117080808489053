import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html'
})
export class ProductComponent {
    @Input() public productId: string;
    @Input() public productTitle: string;
    @Input() public productDescription: string;
    @Input() public className: string;
    @Input() public numeroUnicoDocumento: string;
    @Input() public chaveSeguranca: string = null;
    constructor(
        private router: Router
    ) {
    }

    openDocument() {
        sessionStorage.setItem("productTitle", this.productTitle);
        if (this.chaveSeguranca != null) {
            this.router.navigate([`/documents/${this.productId}/${this.numeroUnicoDocumento}/${this.chaveSeguranca}`]);
        }
        else {
            this.router.navigate([`/documents/${this.productId}/${this.numeroUnicoDocumento}`]);
        }
    }
    keyToClick(event) {
        if (event.key == "Enter")
            $(event.target).click();
    }
}