import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { HomeFooterModule } from 'src/app/controls/home-footer/home-footer.module';
import { GoToTopButtonModule } from 'src/app/controls/go-to-top-button/go-to-top-button.module';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ForgotPasswordModule } from 'src/app/controls/forgot-password/forgot-password.module';
import { FirstAccessModule } from 'src/app/controls/first-access/first-access.module';
import { AppRoutingModule } from 'src/app/app.routing.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CaptchaModule } from 'src/app/controls/captcha/captcha.module';
import { SocialModule } from 'src/app/controls/social/social.module';

import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HomeFooterModule,
        GoToTopButtonModule,
        AnimateOnScrollModule.forRoot(),
        ForgotPasswordModule,
        FirstAccessModule,
        AppRoutingModule,
        DirectivesModule,
        CaptchaModule,
        SocialModule
    ],
    exports: [LoginComponent],
    providers: [],
    bootstrap: [LoginComponent]
})
export class LoginModule { }