import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { ICompany } from 'src/app/models/ICompany';
import { CompanyService } from 'src/app/services/business/company.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { SecurityService } from 'src/app/services/business/security.service';
import { Params, Router } from '@angular/router';


declare var $: any;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
    public newEmail: any;
    public entity: {
        Id: any,
        IdAccess: any,
        CPF: any,
        CommandArgument: any,
        email: any,
        name: any,
        chaveSeguranca: any,
        firstAccess: any,
        lastAccess: any,
        enableResetAccess: boolean
    }
    constructor(private adminService: AdminService,
        private companyService: CompanyService,
        private messageService: MessageSwalService,
        private securityService: SecurityService,
        private router: Router) {
        this.clear();
    }

    ngOnInit(): void {
    }

    searchInsured() {
        this.newEmail = null;
        this.securityService.SearchInsured(this.transformNumberToCpf(this.entity.CPF)).subscribe((result: any) => {
            if (result != null) {
                if (result.content == null) {
                    this.messageService.displayValidationMessage('Usuario não encontrado');
                    return;
                }
                this.entity.Id = result.content.Id;
                this.entity.IdAccess = result.content.IdAccess;
                this.entity.email = result.content.email;
                this.entity.name = result.content.name;
                this.entity.chaveSeguranca = result.content.chaveSeguranca;
                this.entity.firstAccess = result.content.firstAccess;
                this.entity.lastAccess = result.content.lastAccess;
                this.entity.CommandArgument = result.content.CommandArgument;
                this.entity.enableResetAccess = result.content.enableResetAccess;
            }
        });
    }

    public transformNumberToCpf(cpf) {
        cpf = cpf.replace(/[^\d]/g, "");

        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    clear() {
        this.newEmail = null;
        this.entity = {
            Id: null, IdAccess: null, CommandArgument: null, email: null,
            name: null, chaveSeguranca: null, firstAccess: null, lastAccess: null, CPF: null, enableResetAccess: false
        };
    }

    resetAccess() {
        if (this.entity.IdAccess == null) {
            this.messageService.displayValidationMessage('Erro - Não será possível executar essa ação');
            this.clear();
            return;
        }
        this.securityService.ResetAccess(this.entity.IdAccess).subscribe((result: any) => {
            if (result != null) {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });

            }
        });
    }

    TrySimulateAccess() {
        this.router.navigate(['/productsadmin/' + this.entity.Id]);
    }

    alterEmail() {
        if (this.newEmail == null || this.newEmail == "") {
            this.messageService.displayValidationMessage('Digite o novo E-mail');
            return;
        }
        this.securityService.ChangeEmail(this.transformNumberToCpf(this.entity.CPF), this.newEmail).subscribe((result: any) => {
            if (result != null) {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
            }
        });
    }

    exportPDF() {
        this.securityService.ExportPDF(this.transformNumberToCpf(this.entity.CPF)).subscribe((result: any) => {
            if (result != null) {
                if (result.warningLevel == 1) {
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:application/pdf;base64,' + result.content.content);
                    element.setAttribute('download', "ProtocoloSegurado" + ".pdf");
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    return;
                } else {
                    this.messageService.displayValidationMessage('Erro na geração PDF');
                    return;
                }

            }
        });
    }


}