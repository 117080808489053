import { NgModule } from '@angular/core';
import { DocumentsComponent } from './documents.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeFooterModule } from 'src/app/controls/home-footer/home-footer.module';

@NgModule({
    declarations: [DocumentsComponent],
    imports: [
        CommonModule, 
        HeaderModule, 
        RouterModule,
        HomeFooterModule
    ],
    exports: [DocumentsComponent],
    providers: [],
    bootstrap: [DocumentsComponent]
})
export class DocumentsModule {}