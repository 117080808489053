import { Directive, ElementRef } from '@angular/core';
declare var $: any;
@Directive({
  selector: '[app-password-visibility-toggleable]'
})
export class PasswordVisibilityToggleableDirective {
    private _shown = false;

    constructor(private el: ElementRef) {
        this.setup();
    }

    toggle(icon: HTMLElement) {
        this._shown = !this._shown;
        if (this._shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            icon.className = 'icon-icon-nav-ocultar toggle-password';
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            icon.className = 'icon-icon-nav-visualizar toggle-password';
        }
    }
    
    setup() {
        const parent = this.el.nativeElement.parentNode;
        const icon = document.createElement('i');
        icon.id = 'toggle-password';
        icon.className = 'icon-icon-nav-visualizar toggle-password';
        icon.addEventListener('click', (event) => {
            this.toggle(icon);
        });
        parent.appendChild(icon);
    }
}