import { Injectable } from '@angular/core';
import { IMessage } from 'src/app/models/IMessage';

declare var Swal: any;

@Injectable({
    providedIn: 'root'
})
export class MessageSwalService {
    swalWithBootstrapButtons: any;
    constructor() {
        Swal = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger dark',
                cancelButton: 'btn btn-cancel marginleftbtncancel',
                title: 'swal-title',
                popup: 'swal-popup',
                content: 'swal-content'
            },
            buttonsStyling: false,
            showCloseButton: true
        });
    }

    displayValidationMessage(msg: string) {
        this.displayMessage({ warninglevel: 3, message: msg }, null);
    }

    displayMessage(msg: IMessage, callback?: any): void {
        switch (msg.warninglevel) {
            case 0:
                if (callback) {
                    Swal.fire('', '<br><strong class="bradesco-bold" style="font-size:22px;">Informação</strong><br><br><span class="bradesco-light">' +
                    msg.message + '</span>', 'info').then(callback);
                } else {
                    Swal.fire('', '<br><strong class="bradesco-bold" style="font-size:22px;">Informação</strong><br><br><span class="bradesco-light">' + 
                    msg.message + '</span>', 'info');
                }
                break;
            case 1:
                if (callback) {
                    Swal.fire('', '<br><strong class="bradesco-bold" style="font-size:22px;">Sucesso</strong><br><br><span class="bradesco-light">' + 
                    msg.message + '</span>', 'success').then(callback);
                } else {
                    Swal.fire('', '<br><strong class="bradesco-bold" style="font-size:22px;">Sucesso</strong><br><br><span class="bradesco-light">' + 
                    msg.message + '</span>', 'success');
                }
                break;
            case 2:
                Swal.fire('', '<br><strong class="bradesco-bold" style="font-size:22px;">Alerta</strong><br><br><span class="bradesco-light">' + 
                msg.message + '</span>', 'warning');
                break;
            default:
                Swal.fire('', '<br><strong class="bradesco-bold" style="font-size:22px;">Erro</strong><br><br><span class="bradesco-light">' + 
                msg.message + '</span>', 'error');
                break;
        }
    }

    public displayTermsOfUse(): Promise<any> {
        return Swal.fire({
            title: '',
            type: 'warning',
            typeColor: '#FFCB3A',
            html:
                '<br><strong class="bradesco-bold" style="font-size:22px;">Atenção</strong><br><br>' +
                '<span class="bradesco-light">Ao <strong class="bradesco-bold">clicar em continuar</strong> você <br/>declara que está de acordo com os ' +
                '<a class="bradesco-bold" href="assets/downloads/Termo de Uso - Portal Proteção.pdf" target="_blank">Termos de Uso</a> do Portal Proteção.</span>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText:
                'Continuar',
            confirmButtonAriaLabel: 'Continuar',
            cancelButtonText:
                'Não concordo',
            cancelButtonAriaLabel: 'Não concordo'
        });
    }
}
