import { NgModule } from '@angular/core';
import { BlockCopyPasteDirective } from './block-copy-paste/block-copy-paste.directive';
import { CPFMaskDirective } from './cpf-mask/cpf-mask.directive';
import { PasswordVisibilityToggleableDirective } from './password-visibility-toggleable/password-visibility-toggleable.directive';

@NgModule({
    declarations: [BlockCopyPasteDirective, CPFMaskDirective, PasswordVisibilityToggleableDirective],
    imports: [],
    exports: [BlockCopyPasteDirective, CPFMaskDirective, PasswordVisibilityToggleableDirective],
    providers: [],
    bootstrap: []
})
export class DirectivesModule {

}