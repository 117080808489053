import { NgModule } from '@angular/core';
import { SearchComponent } from './search.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
    declarations: [SearchComponent],
    imports: [HeaderModule, CommonModule, FormsModule, NgxMaskModule.forRoot()],
    exports: [SearchComponent],
    providers: [],
    bootstrap: [SearchComponent]
})
export class SearchModule { }