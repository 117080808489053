import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { IProduct } from 'src/app/models/IProduct';
import { ProductService } from 'src/app/services/business/product.service';
import { IDocument } from 'src/app/models/IDocument';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { DocumentService } from 'src/app/services/business/document.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var $: any;

@Component({
    selector: 'app-manager-products',
    templateUrl: './manager-products.component.html'
})
export class ManagerProductsComponent implements OnInit {

    public tableData: any;
    public bases: IProduct[] = [];
    public products: IProduct[] = [];
    public selectedBase: number = null;
    public selectedProduct: any;
    public htmlContent: any;
    public active: any;
    constructor(private adminService: AdminService,
        private productService: ProductService,
        private messageService: MessageSwalService,
        private documentService: DocumentService) {
        this.active = false;
    }

    ngOnInit(): void {
        this.loadBases();
    }

    loadBases() {
        this.productService.ListBase().subscribe((result: any) => {
            if (result != null) {
                this.bases = result.content;
            }
        });
    }

    getOriginalProduct() {

        this.active = this.selectedProduct.inativo;
        this.htmlContent = this.selectedProduct.template;

    }


    loadProduct() {
        this.selectedProduct = null;
        this.htmlContent = null;
        this.active = false;
        this.productService.ListProductByBase(this.selectedBase).subscribe((result: any) => {
            if (result != null) {
                this.products = result.content;
                if (this.products.length == 0)
                    this.messageService.displayValidationMessage('Nenhum produto encontrado');
            }
        });
    }

    saveProduct() {
        this.productService.SaveProduct(this.selectedProduct.id, this.active, this.htmlContent).subscribe((result: any) => {
            if (result != null) {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                if (result.warningLevel == 1) {
                    this.loadProduct();
                    this.messageService.displayMessage({ warninglevel: result.warningLevel, message: "Produto salvo com sucesso!" });
                }
            }
        });
    }
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '400px',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: '...',
        defaultParagraphSeparator: '<br>',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' }
        ],
        customClasses: [
            {
                name: 'h1',
                class: '',
                tag: 'h1'
            },
            {
                name: 'h2',
                class: '',
                tag: 'h2'
            }

        ],
        sanitize: false,
        toolbarPosition: 'top'
    };



}