import { NgModule } from '@angular/core';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InconsistencyComponent } from './Inconsistency.component';

@NgModule({
    declarations: [InconsistencyComponent],
    imports: [HeaderModule, CommonModule, FormsModule],
    exports: [InconsistencyComponent],
    providers: [],
    bootstrap: [InconsistencyComponent]
})
export class InconsistencyModule { }