import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { HomeFooterModule } from 'src/app/controls/home-footer/home-footer.module';
import { GoToTopButtonModule } from 'src/app/controls/go-to-top-button/go-to-top-button.module';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { AppRoutingModule } from 'src/app/app.routing.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CaptchaModule } from 'src/app/controls/captcha/captcha.module';
import { RouteForgotPasswordComponent } from './route-forgot-password.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [RouteForgotPasswordComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HomeFooterModule,
        GoToTopButtonModule,
        AnimateOnScrollModule.forRoot(),
        AppRoutingModule,
        DirectivesModule,
        CaptchaModule
    ],
    exports: [RouteForgotPasswordComponent],
    providers: [],
    bootstrap: [RouteForgotPasswordComponent]
})
export class RouteForgotPasswordModule { }