import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { IProduct } from 'src/app/models/IProduct';
import { ProductService } from 'src/app/services/business/product.service';
import { IDocument } from 'src/app/models/IDocument';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { DocumentService } from 'src/app/services/business/document.service';

declare var $: any;

@Component({
    selector: 'app-manual',
    templateUrl: './manual.component.html'
})
export class ManualComponent implements OnInit {
    public tableData: any;
    public bases: IProduct[] = [];
    public products: IProduct[] = [];
    public documents: IDocument[] = [];
    public reportType: number;
    public selectedBase: number = null;
    public selectedProduct: number = null;
    public documentUrl: any;
    public manual: any;
    public filecontent: any;
    constructor(private adminService: AdminService,
        private productService: ProductService,
        private messageService: MessageSwalService,
        private documentService: DocumentService) {
    }

    ngOnInit(): void {
        this.loadBases();
    }

    loadBases() {
        this.productService.ListBase().subscribe((result: any) => {
            if (result != null) {
                this.bases = result.content;
            }
        });
    }


    loadProduct() {
        this.selectedProduct = null;
        this.documents = [];
        this.productService.ListProductByBase(this.selectedBase).subscribe((result: any) => {
            if (result != null) {
                this.products = result.content;
                if (this.products.length == 0)
                    this.messageService.displayValidationMessage('Nenhum produto encontrado');
            }
        });
    }

    GetManualByProduct() {
        this.manual = null;
        this.documentService.GetManualByProduct(this.selectedProduct, this.selectedBase).subscribe((result: any) => {
            if (result != null) {
                this.manual = result.content;
                if (this.manual == null)
                    this.messageService.displayValidationMessage('Manual não encontrado.');
            }
        });

    }

    ChangeManual() {
        this.documentService.ChangeManual(this.manual.id, this.filecontent).subscribe((result: any) => {
            if (result != null) {
                this.manual = result.content;
                if (this.manual == null)
                    this.messageService.displayValidationMessage('Manual não encontrado.');
                else {
                    this.messageService.displayMessage({ warninglevel: 1, message: 'O manual foi trocado com sucesso.' });
                }
            }
        }, error => this.messageService.displayValidationMessage('Erro ao trocar o manual.')
        );
    }

    getBase64File(file) {
        this.filecontent = null;
        var reader = new FileReader();
        var ctx = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
            ctx.filecontent = reader.result as string;
            ctx.filecontent = ctx.filecontent.split(',')[1];
        }
    }


}