import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { CommonModule } from '@angular/common';
import { IProduct } from 'src/app/models/IProduct';
import * as FW from "../../framework/core";
import { IQueueFile } from 'src/app/models/IQueueFile';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
    public queueFiles: IQueueFile[];

    constructor(private adminService: AdminService) {
    }

    ngOnInit(): void {
        this.adminService.listQueueFiles().subscribe((resp) => {
            this.queueFiles = resp.content;
        });
    }
}