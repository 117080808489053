import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/business/admin.service';
import { ICompany } from 'src/app/models/ICompany';
import { CompanyService } from 'src/app/services/business/company.service';
import { BillingService } from 'src/app/services/business/billing.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';


declare var $: any;

@Component({
    selector: 'app-billing-base',
    templateUrl: './billing-base.component.html'
})
export class BillingBaseComponent implements OnInit {
    public tableData: any;
    public year: any;
    public month: any;

    constructor(private adminService: AdminService,
        private companyService: CompanyService,
        private billingSerivce: BillingService,
        private messageService: MessageSwalService) {
    }

    ngOnInit(): void {
    }



    searchReport() {
        this.billingSerivce.billingBaseCsv(this.year, this.month).subscribe((result: any) => {
            if (result != null) {
                window.open(result.content);              
                return;
            }
            this.messageService.displayValidationMessage('Sem dados de faturamento');
        });
    }
}