import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/business/security.service';
import { ContextService } from 'src/app/services/application/context.service';
import { WarningLevel } from 'src/app/framework/enums';
import { Storage } from "../../framework/storage";
import * as FW from "../../framework/core";
import { Observable, timer } from 'rxjs';
import { map } from "rxjs/Operators";
import { SocialComponent } from '../../controls/social/social.component';
import { HighContrastService } from 'src/app/services/application/highContrast.service';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    @Input() userName: string;
    @ViewChild(SocialComponent)
    public socialComponent: SocialComponent;

    // @Output() darkModeUpdate = new EventEmitter();
    @Input() sourceImgV;

    public expanded: boolean = false;
    public expandedMobile: boolean = false;
    public operacao: boolean = false;
    public filaProcessamento: boolean = false;
    public faturamento: boolean = false;
    public inconsistencia: boolean = false;
    public pesquisa: boolean = false;
    public produtos: boolean = false;
    public manual: boolean = false;
    public seguranca: boolean = false;
    public amostra: boolean = false;
    public baseFaturamento: boolean = false;
    public baseAssinaturas: boolean = false;
    public horaAtual: Observable<Date>;
    public initials: string = '';
    public dropdownIsOpened: boolean = false;

    constructor(
        private messageService: MessageSwalService,
        private context: ContextService,
        private securityService: SecurityService,
        private router: Router,
        private highContrast: HighContrastService
    ) {
    }

    public get isAdmin(): boolean {
        return this.context.authService.isAdmin;
    }

    ngOnInit(): void {
        let perfilAdmin = Storage.getString('perfilAdmin')

        this.horaAtual = timer(0, 1000).pipe(
            map(() => {
                return new Date()
            })
        );

        this.socialComponent.checkDarkMode();

        if (!FW.isNullOrBlank(perfilAdmin) && perfilAdmin != 'null') {
            let json = JSON.parse(perfilAdmin);
            this.operacao = json.operacao;
            this.filaProcessamento = json.filaProcessamento;
            this.faturamento = json.faturamento;
            this.inconsistencia = json.inconsistencia;
            this.pesquisa = json.pesquisa;
            this.manual = json.manual;
            this.produtos = json.produtos;
            this.seguranca = json.seguranca;
            this.amostra = json.amostra;
            this.baseFaturamento = json.baseFaturamento;
            this.baseAssinaturas = json.baseAssinaturas;
        }

        this.userName = this.context.authService.userName;
        if (this.userName) {
            this.userName = this.userName.split(' ')[0].toLowerCase();
            this.initials = this.userName[0].toUpperCase();// + (surnames.length > 1 ? surnames[surnames.length-1][0].toUpperCase() : '');
        }
        debugger;
        // Select the target node.
        var target = document.querySelector('#dropdown-div')

        // Create an observer instance.
        var observer = new MutationObserver((mutations) => {
            debugger;            
            this.adjustColor(JSON.parse($('#dropdown-div').attr('aria-expanded')));
        });

        // Pass in the target node, as well as the observer options.
        observer.observe(target, {
            attributes: true,
            childList: true,
            characterData: true
        });

    }
    adjustColor(expanded:boolean) {
        debugger;
        this.expanded = expanded;
        if (this.expanded == true) {
            $("#card-box").css("background-color", "var(--background-color-offset)");
        } else {
            $("#card-box").css("background-color", "var(--background-color-white)");
        }
    }
    keyLogOut(event) {
        if (event.key == 'Enter')
            this.logoff();
    }
    keyGoHome(event) {
        if (event.key == 'Enter')
            this.goToHome();
    }

    keyToClick(event) {
        debugger;
        if (event.key == 'Enter') {
            $(event.target).click();
            this.switchColor();
        }
    }
    // feedback() {
    //     // this.darkModeUpdate.emit(this.socialComponent.sourceImgV.toString());
    // }

    // reciverFeedback(imagePath) {
    //     console.log('from Header' + imagePath)
    //     this.sourceImgV = imagePath;

    //     this.feedback();
    // }

    openModalAdmin() {
        $("#actionModal").modal('show');
    }


    switchColorMobile() {
        if (!this.expandedMobile) {
            this.expandedMobile = true;
            $("#card-box-mobile").css("background-color", "var(--background-color-offset)");
        } else {
            this.expandedMobile = false;
            $("#card-box-mobile").css("background-color", "var(--background-color-white)");
        }
    }

    switchColor() {
        if (!this.expanded) {
            this.expanded = true;
            $("#card-box").css("background-color", "var(--background-color-offset)");
        } else {
            this.expanded = false;
            $("#card-box").css("background-color", "var(--background-color-white)");
        }
    }

    goToAdmin() {
        this.closeModalAdmin();
        this.router.navigate(['/admin']);
    }

    goToHome() {
        this.router.navigate(['/products']);
    }

    goToInconsistency() {
        this.closeModalAdmin();
        this.router.navigate(['/inconsistency']);
    }

    goToManagerProducts() {
        this.closeModalAdmin();
        this.router.navigate(['/managerproducts']);
    }
    goToBillingBase() {
        this.closeModalAdmin();
        this.router.navigate(['/billing-base']);
    }
    goToSignBase() {
        this.closeModalAdmin();
        this.router.navigate(['/sign-base']);
    }
    goToBilling() {
        this.closeModalAdmin();
        this.router.navigate(['/billing']);
    }
    goToSummary() {
        this.closeModalAdmin();
        this.router.navigate(['/summary']);
    }
    gotToSearch() {
        this.closeModalAdmin();
        this.router.navigate(['/search']);
    }

    goToManual() {
        this.closeModalAdmin();
        this.router.navigate(['/manual']);
    }
    goToManager() {
        this.closeModalAdmin();
        this.router.navigate(['/manager']);
    }
    goToSample() {
        this.closeModalAdmin();
        this.router.navigate(['/sample']);
    }
    goToSampleData() {
        this.closeModalAdmin();
        this.router.navigate(['/sample-data']);
    }
    goToTemplateData() {
        this.closeModalAdmin();
        this.router.navigate(['/sample-template']);
    }

    closeModalAdmin() {
        $("#actionModal").modal('hide');
    }


    submitPasswordChange() {
        var currentPassword = $('#changePasswordCurrentPassword').val();
        var newPassword = $('#changePasswordNewPassword').val();
        var newPasswordConfirm = $('#changePasswordNewPasswordConfirm').val();

        if (currentPassword == '') {
            this.messageService.displayValidationMessage('Preencha a senha atual');
        } else if (newPassword == '') {
            this.messageService.displayValidationMessage('Preencha a nova senha');
        } else if (newPasswordConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a confirmação da nova senha');
        } else if (newPassword != newPasswordConfirm) {
            this.messageService.displayValidationMessage('A nova senha e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.changeNewPassword(currentPassword, newPassword, newPasswordConfirm).pipe().subscribe((result) => {
                if (result != null) {
                    this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message }, () => {
                        if (result.warningLevel == WarningLevel.Success)
                            $('#changePasswordModal').modal('hide');
                    });
                }
            });
        }
    }

    logoff() {
        this.context.authService.logoff();
    }
}