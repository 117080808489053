import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { SocialModule } from 'src/app/controls/social/social.module';

@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        SocialModule
    ],
    exports: [HeaderComponent],
    providers: [],
    bootstrap: [HeaderComponent]
})
export class HeaderModule {}