import { Component, ViewChild } from '@angular/core';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/business/security.service';
import { CaptchaComponent } from '../captcha/captcha.component';
import { WarningLevel } from 'src/app/framework/enums';
declare var $: any;

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

    @ViewChild(CaptchaComponent)
    private captchaComponent: CaptchaComponent;
    public step: number = 1;
    public idcaptcha: string;

    constructor(
        private securityService: SecurityService,
        private messageService: MessageSwalService,
        private router: Router
    ) {
    }

    ngOnInit() {
        $('#formForgotPasswordFirstStep').css('display', 'block');
        $('#formForgotPasswordSecondStep').css('display', 'none');

        this.idcaptcha = "captchafp"

        this.step = 1;

        this.addRecaptchaScript();
    }

    addRecaptchaScript() {
        window['grecaptchaCallbackfp'] = () => {
            this.captchaComponent.renderReCaptcha();
        }
       
        (function(d, s, id, obj){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallbackfp&render=explicit";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk-fp', this));
    }

    submitForgotPassword() {
        var document: string;
        var email: string;
        var emailConfirm: string;

        document = $('#forgotPasswordCPF').val().trim();
        email = $('#forgotPasswordEmail').val().trim();
        emailConfirm = $('#forgotPasswordEmailConfirm').val().trim();

        if (document == '') {
            this.messageService.displayValidationMessage('Preencha o CPF');
        } else if (email == '') {
            this.messageService.displayValidationMessage('Preencha o E-mail');
        } else if (emailConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de E-mail');
        } else if (email != emailConfirm) {
            this.messageService.displayValidationMessage('O e-mail e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.recoveryPassword({
                document: document,
                email: email,
                emailConfirm: emailConfirm,
                captcha: this.captchaComponent.getCaptcha()
            }).pipe().subscribe((result) => {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message }, () => {
                    $('#formForgotPasswordFirstStep').css('display', 'none');
                    $('#formForgotPasswordSecondStep').css('display', 'block');

                    this.step = 2;
                });
            })
        }
    }

    changePassword() {
        var email: string;
        var token: string;
        var password: string;
        var passwordConfirm: string;

        email = $('#forgotPasswordEmail').val().trim();
        token = $('#forgotPasswordToken').val().trim();
        password = $('#forgotPasswordPassword').val();
        passwordConfirm = $('#forgotPasswordPasswordConfirm').val();

        if (token == '') {
            this.messageService.displayValidationMessage('Preencha o Token');
        } else if (password == '') {
            this.messageService.displayValidationMessage('Preencha a senha');
        } else if (passwordConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de Senha');
        } else if (password != passwordConfirm) {
            this.messageService.displayValidationMessage('A senha e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.sendPasswordChangeToken({
                document: '',
                email: email,
                emailConfirm: '',
                token: token,
                password: password,
                passwordConfirm: passwordConfirm,
                captcha: ''
            }).pipe().subscribe((result) => {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                if (result.warningLevel == WarningLevel.Success) {
                    $('#formForgotPasswordFirstStep').css('display', 'block');
                    $('#formForgotPasswordSecondStep').css('display', 'none');
                    $('#forgotPasswordModal').modal('hide');
                    this.step = 1;
                }
            });
        }
    }
}