import { NgModule } from '@angular/core';
import { SignBaseComponent } from './sign-base.component';
import { HeaderModule } from 'src/app/controls/header/header.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [SignBaseComponent],
    imports: [HeaderModule, CommonModule, FormsModule],
    exports: [SignBaseComponent],
    providers: [],
    bootstrap: [SignBaseComponent]
})
export class SignBaseModule { }