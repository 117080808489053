import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ProductService } from 'src/app/services/business/product.service';
import { IProduct } from 'src/app/models/IProduct';
import * as FW from "../../framework/core";
import { HeaderComponent } from '../../controls/header/header.component';
import { SocialComponent } from '../../controls/social/social.component';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { SecurityService } from 'src/app/services/business/security.service';
import { WarningLevel } from 'src/app/framework/enums';
import { Router } from '@angular/router';

declare var $: any;
@Component({
    selector: 'app-alter-password',
    templateUrl: './alter-password.component.html'
})
export class AlterPasswordComponent implements OnInit {
    @ViewChild(HeaderComponent)
    private headerComponent: HeaderComponent;

    constructor(
        private messageService: MessageSwalService,
        private router: Router,
        private securityService: SecurityService
    ) {
    }

    @ViewChild(SocialComponent)
    @Input() headerSourceImgV;

    public chaveSeguranca: any = null;

    @Input() sourceImgV;

    ngOnInit(): void {
    }
    goToHome() {
        this.router.navigate(['/products']);
    }

    submitPasswordChange() {
        var currentPassword = $('#changePasswordCurrentPassword2').val();
        var newPassword = $('#changePasswordNewPassword2').val();
        var newPasswordConfirm = $('#changePasswordNewPasswordConfirm2').val();

        if (currentPassword == '') {
            this.messageService.displayValidationMessage('Preencha a senha atual');
        } else if (newPassword == '') {
            this.messageService.displayValidationMessage('Preencha a nova senha');
        } else if (newPasswordConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a confirmação da nova senha');
        } else if (newPassword != newPasswordConfirm) {
            this.messageService.displayValidationMessage('A nova senha e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.changeNewPassword(currentPassword, newPassword, newPasswordConfirm).pipe().subscribe((result) => {
                if (result != null) {
                    if (result.warningLevel == WarningLevel.Success) {
                        $('#formFirstStep').css('display', 'none');
                        $('#formSecondStep').css('display', 'block');
                    }
                    else {
                        this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                    }
                }
            });
        }
    }

    reciverFeedback(imagePath) {
        console.log('from Products' + imagePath)
        this.sourceImgV = imagePath;
    }
}