import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ProductService } from 'src/app/services/business/product.service';
import { IProduct } from 'src/app/models/IProduct';
import * as FW from "../../framework/core";
import { HeaderComponent } from '../../controls/header/header.component';
import { SocialComponent } from '../../controls/social/social.component';

declare var $: any;
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html'
})
export class ProductsComponent implements OnInit {
    @ViewChild(HeaderComponent)
    public headerComponent: HeaderComponent;

    
    @ViewChild(SocialComponent)
    @Input() headerSourceImgV;

    @Input() productList: IProduct[];
    public chaveSeguranca: any = null;
    
    @Input() sourceImgV;

    constructor(private productService: ProductService) {
        this.chaveSeguranca = null;
        $(window).scrollTop({ top: 0, behavior: 'smooth' });
    }

    public hidePrivacyDisclaimer: boolean = false;

    ngOnInit(): void {
        console.log(this.headerSourceImgV);

        $('body').css("background-color", "var(--background-color-offset)");  
        this.hidePrivacyDisclaimer = (sessionStorage.getItem("hide_privacy_disclaimer") || "0") == "1";

        this.productService.listProducts().subscribe((resp) => {
            this.productList = resp.content;
        });
    }
    ngAfterViewInit(): void {
    }

    public dismissPrivacyDisclaimer(): void {
        this.hidePrivacyDisclaimer = true;
        sessionStorage.setItem("hide_privacy_disclaimer", "1");
    }

    reciverFeedback(imagePath) {
        console.log('from Products' + imagePath)
        this.sourceImgV = imagePath;
    }
}