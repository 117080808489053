import { NgModule } from '@angular/core';
import { CaptchaComponent } from './captcha.component';

@NgModule({
    declarations: [CaptchaComponent],
    imports: [],
    exports: [CaptchaComponent],
    providers: [],
    bootstrap: [CaptchaComponent]
})
export class CaptchaModule {

}