import { NgModule } from '@angular/core';
import { SocialComponent } from './social.component';

@NgModule({
    declarations: [SocialComponent],
    imports: [],
    exports: [SocialComponent],
    providers: [],
    bootstrap: [SocialComponent]
})
export class SocialModule {

}