import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CaptchaService } from 'src/app/services/application/captcha.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html'
})
export class CaptchaComponent implements OnInit {
  @Input() captchaValue: string;
  @Input() captchaToken: string;
  @Input() idcaptcha: string;
  @ViewChild('captchaField') captchaField;
  @ViewChild('recaptcha') recaptchaElement: ElementRef;

  constructor(private captchaService: CaptchaService) {

  }

  ngOnInit(): void {

  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement.id, {
      'sitekey': environment.recaptcha.siteKey,
      'callback': (response) => {
        this.captchaToken = response;
      }
    });

  }



  getCaptcha(): string {
    return this.captchaToken; //+ '|' + this.captchaField.nativeElement.value.trim().toLowerCase()
  }
}