import { Injectable } from '@angular/core';
import { ContextService } from '../application/context.service';
import { APIService } from '../application/api.service';
import { IAppContext } from 'src/app/framework/interfaces';
import * as FW from "../../framework/core";
import { WarningLevel } from 'src/app/framework/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICompany } from 'src/app/models/ICompany';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    public get serviceName(): string { return "Company" };

    constructor(public context: ContextService, private api: APIService) {
    }

    public listCompanyBilling(): Observable<FW.Envelope<ICompany[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.api.get(ctx, '/company/listcompanybilling');
    }
}