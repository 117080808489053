import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { ContextService } from 'src/app/services/application/context.service';
import { HighContrastService } from 'src/app/services/application/highContrast.service';
import { Observable, of } from 'rxjs';


@Component({
    selector: 'app-home-footer',
    templateUrl: './home-footer.component.html'
})
export class HomeFooterComponent implements OnInit {
    @Input() sourceImgV;

    public highContrast: Boolean = true;

    constructor(
        private context: ContextService,
        private highContrastService: HighContrastService) {

    }

    ngOnInit(): void {
        this.highContrastService.mySubject.subscribe(mode => {
            this.highContrast = mode;
        });

        this.checkDarkMode();
    }

    openUrl(event, url) {
        if (event.key == "Enter") {
            window.location.href = url;
        }
    }

    scroll() {
        var element = document.getElementsByTagName("header");
        element[0].scrollIntoView({ behavior: "smooth" });
    }

    checkDarkMode() {
        this.highContrast = (document.documentElement.getAttribute("data-theme") == 'dark')
    }
}