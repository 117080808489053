import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { ForgotPasswordComponent } from './forgot-password.component';
import { serviceProviders } from 'src/app/services/serviceprovider';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CaptchaModule } from '../captcha/captcha.module';
import { SocialModule } from '../social/social.module';

@NgModule({
    declarations: [ForgotPasswordComponent],
    imports: [CommonModule, BrowserModule, RouterModule, DirectivesModule, CaptchaModule, SocialModule],
    exports: [ForgotPasswordComponent],
    providers: [serviceProviders],
    bootstrap: [ForgotPasswordComponent]
})
export class ForgotPasswordModule { }