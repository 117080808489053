import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { SecurityService } from 'src/app/services/business/security.service';
import { ContextService } from 'src/app/services/application/context.service';
import * as FW from "src/app/framework/core";
import { CaptchaComponent } from 'src/app/controls/captcha/captcha.component';
import { TranslateService } from '@ngx-translate/core';
import { ILoginResult } from 'src/app/models/ILoginResult';
import { WarningLevel } from 'src/app/framework/enums';
declare var $: any;

@Component({
    selector: 'app-route-first-access',
    templateUrl: './route-first-access.component.html',
    providers: [CaptchaComponent]
})
export class RouteFirstAccessComponent implements OnInit, AfterViewInit {

    @ViewChild(CaptchaComponent)
    private captchaComponent: CaptchaComponent;

    public sourceImgH: String = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
    public idcaptcha: string;
    constructor(
        private router: Router,
        private context: ContextService,
        private messageService: MessageSwalService,
        private securityService: SecurityService,
        private translateService: TranslateService) {
    }
    ngOnInit(): void {
        $(window).scrollTop({ top: 0, behavior: 'smooth' });
        $('#formFirstStep').css('display', 'block');
        $('#formSecondStep').css('display', 'none');
        $('#formThirdStep').css('display', 'none');
        $('body').css("background-color", "var(--background-color-white)");
        this.idcaptcha = "captchrfa";
        this.addRecaptchaScript();
        this.checkDarkMode();
    }

    keyGoHome(event) {
        if (event.key == 'Enter')
            this.goToHome();
    }
    ngAfterViewInit(): void {
    }

    checkDarkMode() {
        if (document.documentElement.getAttribute("data-theme") == 'dark') {
            this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_WHITE_H.svg"
        } else {
            this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
        }
    }

    addRecaptchaScript() {
        if (window['grecaptcha'] == undefined || window['grecaptcha'] == null) {
            window['grecaptchaCallbackrfa'] = () => {
                this.captchaComponent.renderReCaptcha();
            }
        } else {
            setTimeout(() => {
                this.captchaComponent.renderReCaptcha();
            }, 100);
        }

        (function (d, s, id, obj) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallbackrfa&render=explicit";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk-fa', this));
    }

    goToHome() {
        this.router.navigate(['/']);
    }
    
    openUrl(event, url) {
        if (event.key == "Enter") {
            window.location.href = url;
        }
    }

    goToLogin() {
        this.router.navigate(['/login']);
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: "smooth" });
    }



    submitFirstAccess() {
        var document: string;
        var email: string;
        var emailConfirm: string;
        var recaptcha: any;

        document = $('#firstAccessCPF').val().trim();
        email = $('#firstAccessEmail').val().trim();
        emailConfirm = $('#firstAccessEmailConfirm').val().trim();
        recaptcha = this.captchaComponent.getCaptcha();
        if (document == '') {
            this.messageService.displayValidationMessage('Preencha o CPF');
        } else if (document.length < 14) {
            this.messageService.displayValidationMessage('CPF Inválido');
        } else if (email == '') {
            this.messageService.displayValidationMessage('Preencha o E-mail');
        } else if (emailConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de E-mail');
        } else if (email != emailConfirm) {
            this.messageService.displayValidationMessage('O e-mail e o campo de confirmação devem ser iguais');
        } else if (recaptcha == null || recaptcha == undefined ||
            recaptcha == '') {
            this.messageService.displayValidationMessage('Preencha o Recaptcha');
        } else {
            this.messageService.displayTermsOfUse().then((optin) => {
                if (optin.value) {
                    this.securityService.getFirstAccessToken({
                        document: document,
                        email: email,
                        emailConfirm: emailConfirm,
                        token: '',
                        password: '',
                        passwordConfirm: '',
                        captcha: recaptcha
                    }).pipe().subscribe((result) => {
                        if (result.warningLevel == WarningLevel.Success) {
                            $('#formFirstStep').css('display', 'none');
                            $('#formSecondStep').css('display', 'block');
                            $('.footer-login').css('position', 'fixed');
                            $('.footer-login').css('bottom', '0');
                            $('#formThirdStep').css('display', 'none');
                        } else {
                            this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                        }
                    });
                }
            });
        }
    }

    submitFirstAccessSecondStep() {
        var email: string;
        var token: string;
        var password: string;
        var passwordConfirm: string;

        email = $('#firstAccessEmail').val().trim();
        password = $('#firstAccessPassword').val().trim();
        passwordConfirm = $('#firstAccessPasswordConfirm').val().trim();

        // Em 14/06/2020 manutenção por Bruno Leitão a pedido da VALID: não enviar mais token de primeiro acesso

        // token = $('#firstAccessToken').val().trim();

        // if (token == '') {
        //     this.messageService.displayValidationMessage('Preencha o Token');
        // } else 

        if (password == '') {
            this.messageService.displayValidationMessage('Preencha a senha');
        } else if (passwordConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de Senha');
        } else if (password != passwordConfirm) {
            this.messageService.displayValidationMessage('A senha e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.sendFirstAccessToken({
                document: '',
                email: email,
                emailConfirm: '',
                token: token,
                password: password,
                passwordConfirm: passwordConfirm,
                captcha: ''
            }).pipe().subscribe((result) => {
                if (result.warningLevel == WarningLevel.Success) {
                    $('#formFirstStep').css('display', 'none');
                    $('#formSecondStep').css('display', 'none');
                    $('#formThirdStep').css('display', 'block');
                } else {
                    this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });

                }
            });
        }
    }
}