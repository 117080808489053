import { Component, Input, OnInit } from '@angular/core';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { ProtocolService } from 'src/app/services/business/protocol.service';


declare var $: any;

@Component({
    selector: 'app-inconsistency',
    templateUrl: './inconsistency.component.html'
})
export class InconsistencyComponent implements OnInit {
    public tableData: any;
    public startDate: any;
    public endDate: any;

    constructor(private protocolService: ProtocolService,
        private messageService: MessageSwalService) {
    }

    ngOnInit(): void {

    }


    searchIconsistencyReport() {
        this.protocolService.inconsistencyReport(this.startDate, this.endDate).subscribe((result: any) => {
            if (result != null) {
                if (result.content != null) {
                    let dateTime = new Date()
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/csv;base64,' + result.content);
                    element.setAttribute('download', "RELATORIO_FATURAMENTO_" + dateTime.toJSON() + ".csv");
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    return;
                }
            }
            this.messageService.displayValidationMessage('Sem dados nesse período');
        });
    }
}