import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class HighContrastService {
    public highContrast: Boolean = false;

    // meuObservable = new Observable((observer) => {
    //     console.log('Aqui começa o Observable');
    
    //     observer.next('1');
    //     observer.next('2');
    //     observer.next('3');
    //     observer.next('4');
    //     observer.next('5');
    //   });

    public mySubject = new Subject<Boolean>();
    
    // myObservable = of(this.highContrast);

    public setHighContrast(): void {
        this.highContrast = !this.highContrast;
        // this.myObservable.subscribe((x)=> { x.next() })
        this.mySubject.next(this.highContrast);
    }
}