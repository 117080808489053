import { Component, Input, OnInit } from '@angular/core';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { AdminService } from 'src/app/services/business/admin.service';
import { SecurityService } from 'src/app/services/business/security.service';

declare var $: any;

@Component({
    selector: 'app-manager',
    templateUrl: './manager.component.html'
})
export class ManagerComponent implements OnInit {
    public tableData: any;
    public editData: boolean = false;
    public admins: any;
    public adminAuxExclude: any;
    public entity: {
        id?: any;
        nomeUsuario?: any;
        email?: any;
        numeroDocumento?: any;
        password?: any;
        hourInitial?: any;
        minInitial?: any;
        hourFinal?: any;
        minFinal?: any;
        operacao?: any;
        pesquisa?: any;
        faturamento?: any;
        inconsistencia?: any;
        produtos?: any;
        manual?: any;
        seguranca?: any;
        amostra?: any;
        filaProcessamento?: any;
    };
    constructor(private adminService: AdminService,
        private securityService: SecurityService,
        private messageService: MessageSwalService) {
        this.editData = false;
        this.Clear();
        this.adminAuxExclude = { nomeUsuario: "" };
    }

    ngOnInit(): void {
        this.loadAdmins();
    }

    loadAdmins() {
        this.securityService.ListAdmins().subscribe((result: any) => {
            if (result != null) {
                this.admins = result.content;


            }
        });
    }

    New() {
        this.editData = true;
        this.Clear();
    }

    Edit(admin) {
        this.entity = {
            id: admin.id, nomeUsuario: admin.nomeUsuario, email: admin.email, password: null, numeroDocumento: admin.numeroDocumento,
            hourFinal: admin.hourFinal, hourInitial: admin.hourInitial, minFinal: admin.minFinal,
            minInitial: admin.minInitial, amostra: admin.amostra, filaProcessamento: admin.filaProcessamento,
            pesquisa: admin.pesquisa, operacao: admin.operacao, inconsistencia: admin.inconsistencia,
            faturamento: admin.faturamento, produtos: admin.produtos,
            manual: admin.manual, seguranca: admin.seguranca
        }
        this.editData = true;

    }

    OpenModalExclude(admin: any) {
        if (admin.id == null) {
            this.messageService.displayValidationMessage('Erro - Não será possível executar essa ação');
            return;
        }
        this.adminAuxExclude = admin;
        $("#modalExclude").modal();
    }

    Excluded() {
        $("#modalExclude").modal('hide');
        if (this.adminAuxExclude.id == null) {
            this.messageService.displayValidationMessage('Erro - Não será possível executar essa ação');
            return;
        }
        this.securityService.ExcludedAdmin(this.adminAuxExclude.id).subscribe((result: any) => {
            if (result != null) {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                this.loadAdmins();

            }
        });
    }

    Back() {
        this.loadAdmins();
        this.editData = false;
        this.Clear();
    }

    Clear() {
        this.entity = {
            id: null, nomeUsuario: null, email: null, password: null, numeroDocumento: null,
            hourFinal: null, hourInitial: null, minFinal: null, minInitial: null, amostra: false, filaProcessamento: false,
            pesquisa: false, operacao: false, inconsistencia: false, faturamento: false, produtos: false,
            manual: false, seguranca: false
        }
    }

    public transformNumberToCpf(cpf) {
        cpf = cpf.replace(/[^\d]/g, "");

        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    Save() {

        if (this.disabledSave()) {
            this.messageService.displayMessage({ warninglevel: 2, message: "Preencha os campos de texto" });
            return;
        }

        if (this.entity.numeroDocumento.length != 11) {
            this.messageService.displayMessage({ warninglevel: 2, message: "Preencha todos os números do CPF." });
            return;
        }
        var startTime = Number(this.entity.hourInitial) * 60 + Number(this.entity.minInitial);
        var endTime = Number(this.entity.hourFinal) * 60 + Number(this.entity.minFinal);

        if (Number(this.entity.hourInitial) > 23 || Number(this.entity.hourFinal) > 23) {
            this.messageService.displayMessage({ warninglevel: 2, message: "Campo de Hora não pode ser maior que 23." });
            return;
        }

        if (Number(this.entity.minInitial) > 59 || Number(this.entity.minFinal) > 59) {
            this.messageService.displayMessage({ warninglevel: 2, message: "Campo de Minuto não pode ser maior que 59." });
            return;
        }

        if (startTime > endTime) {
            this.messageService.displayMessage({ warninglevel: 2, message: "Horário Fim não pode ser maior que horário Início." });
            return;
        }

        this.entity.numeroDocumento = this.transformNumberToCpf(this.entity.numeroDocumento);

        this.securityService.SaveAdmin(this.entity).subscribe((result: any) => {
            if (result != null) {
                this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                if (result.warningLevel == 1) {
                    this.Back();
                }
            }
        });

    }

    disabledSave() {
        return this.entity.minFinal == null || this.entity.minFinal == ""
            || this.entity.hourFinal == null || this.entity.hourFinal == ""
            || this.entity.minInitial == null || this.entity.minInitial == ""
            || this.entity.hourInitial == null || this.entity.hourInitial == ""
            || this.entity.numeroDocumento == null || this.entity.numeroDocumento == ""
            || this.entity.email == null || this.entity.email == ""
            || this.entity.nomeUsuario == null || this.entity.nomeUsuario == "";
    }

}