import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { IDocument } from 'src/app/models/IDocument';
import { DocumentService } from 'src/app/services/business/document.service';
import { APIService } from 'src/app/services/application/api.service';
import { DomSanitizer, SafeResourceUrl,  } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { windowCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CompileShallowModuleMetadata } from '@angular/compiler';
declare var $: any;

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
    public documentList: IDocument[];
    @Input() documentUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    @Input() productTitle: string = sessionStorage.getItem("productTitle");
    @Input() sourceImgV;

    public product: any = {
        firstName: this.productTitle.split(" ").shift(),
        boldName: this.productTitle.split(" ").splice(1).join(" ")
    }
    selectedIndex = 0;
    public chaveSeguranca: any = null;
    currentDocument = {
        id: 0,
        codigoDocumento: '',
        uri: this.sanitizer.bypassSecurityTrustResourceUrl(''),
        nomeProcessado: ''
    };

    constructor(
        private documentService: DocumentService,
        api: APIService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private messageService: MessageSwalService,
        private http: HttpClient
    ) {
        this.chaveSeguranca = this.route.snapshot.paramMap.get('chaveSeguranca');
    }

    selectDocument(index: number) {
        this.selectedIndex = index;
        this.currentDocument = this.documentList[this.selectedIndex];
        this.documentUrl = this.documentService.showDocument(this.currentDocument.id);
        
        // console.log(this.currentDocument.id);
    }
    keyToClick(event){
        if(event.key == "Enter")
        $(event.target).click()
    }

    downLoadSelectedDocument(index: number) {
        this.currentDocument = this.documentList[this.selectedIndex];
        let url = this.documentService.getDowloadDocumentUrl(this.currentDocument.id);

        var element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('target', "_blank");
        element.setAttribute('download', this.currentDocument.nomeProcessado);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }

    elementHoverOn(element): void {
        element.srcElement.children[1].className += ' inner-radius-show';
        element.srcElement.children[2].className += ' inner-radius-show';
    }

    elementHoverOff(element): void {
        element.srcElement.children[1].className = 'zoom-text inner-radius inner-radius-top';
        element.srcElement.children[2].className = 'zoom-text inner-radius inner-radius-bottom';
    }

    ngOnInit(): void {
        this.documentService.listDocuments(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('numeroUnicoDocumento'), this.route.snapshot.paramMap.get('chaveSeguranca')).subscribe((result) => {
            this.documentList = result.content;
            this.selectDocument(0);
        }, (error) => {
            this.messageService.displayMessage({ warninglevel: 2, message: "Usuário não autorizado." });
        });
    }
}