import { NgModule } from '@angular/core';
import { GoToTopButtonComponent } from './go-to-top-button.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [GoToTopButtonComponent],
    imports: [CommonModule],
    exports: [GoToTopButtonComponent],
    providers: [],
    bootstrap: [GoToTopButtonComponent]
})
export class GoToTopButtonModule { }