import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './pages/products/products.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { RouteGuardService } from './services/application/routeguard.service';
import { AdminComponent } from './pages/admin/admin.component';
import { BillingComponent } from './pages/billing/billing.component';
import { SampleComponent } from './pages/sample/sample.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { SearchComponent } from './pages/search/search.component';
import { ManagerComponent } from './pages/manager/manager.component';
import { ManualComponent } from './pages/manual/manual.component';
import { ProductsAdminComponent } from './pages/products-admin/products-admin.component';
import { InconsistencyComponent } from './pages/Inconsistency/Inconsistency.component';
import { ManagerProductsComponent } from './pages/manager-products/manager-products.component';
import { BillingBaseComponent } from './pages/billing-base/billing-base.component';
import { SignBaseComponent } from './pages/sign-base/sign-base.component';
import { SampleDataComponent } from './pages/sample-data/sample-data.component';
import { SampleTemplateComponent } from './pages/sample-template/sample-template.component';
import { RouteFirstAccessComponent } from './pages/route-first-access/route-first-access.component';
import { RouteForgotPasswordComponent } from './pages/route-forgot-password/route-forgot-password.component';
import { AlterPasswordComponent } from './pages/alter-password/alter-password.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'products',
        component: ProductsComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'productsadmin/:id',
        component: ProductsAdminComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'alter-password',
        component: AlterPasswordComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'billing',
        component: BillingComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'billing-base',
        component: BillingBaseComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'sign-base',
        component: SignBaseComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'sample',
        component: SampleComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'sample-data',
        component: SampleDataComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'sample-template',
        component: SampleTemplateComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'search',
        component: SearchComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'manager',
        component: ManagerComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'manual',
        component: ManualComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'inconsistency',
        component: InconsistencyComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'managerproducts',
        component: ManagerProductsComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'documents/:id/:numeroUnicoDocumento',
        component: DocumentsComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'documents/:id/:numeroUnicoDocumento/:chaveSeguranca',
        component: DocumentsComponent,
        canActivate: [RouteGuardService]
    },
    {
        path: 'login',
        component: LoginComponent
    }
    ,
    {
        path: 'firstaccess',
        component: RouteFirstAccessComponent
    }
    ,
    {
        path: 'forgotpassword',
        component: RouteForgotPasswordComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }

