import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { SecurityService } from 'src/app/services/business/security.service';
import { ContextService } from 'src/app/services/application/context.service';
import * as FW from "src/app/framework/core";
import { CaptchaComponent } from 'src/app/controls/captcha/captcha.component';
import { TranslateService } from '@ngx-translate/core';
import { ILoginResult } from 'src/app/models/ILoginResult';
import { WarningLevel } from 'src/app/framework/enums';
declare var $: any;

@Component({
    selector: 'app-route-forgot-password',
    templateUrl: './route-forgot-password.component.html',
    providers: [CaptchaComponent]
})
export class RouteForgotPasswordComponent implements OnInit, AfterViewInit {

    @ViewChild(CaptchaComponent)
    private captchaComponent: CaptchaComponent;
    public step: number = 0;
    public sourceImgH: String = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
    public idcaptcha: string;
    constructor(
        private router: Router,
        private context: ContextService,
        private messageService: MessageSwalService,
        private securityService: SecurityService,
        private translateService: TranslateService) {
        this.step = 0;
    }
    ngOnInit(): void {
        $(window).scrollTop({ top: 0, behavior: 'smooth' });
        $('#formFirstStep').css('display', 'block');
        $('#formSecondStep').css('display', 'none');
        $('#formThirdStep').css('display', 'none');
        $('body').css("background-color", "var(--background-color-white)");
        this.idcaptcha = "captchrfa";
        this.addRecaptchaScript();
        this.checkDarkMode();
    }

    ngAfterViewInit(): void {
    }


    checkDarkMode() {
        if (document.documentElement.getAttribute("data-theme") == 'dark') {
            this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_WHITE_H.svg"
        } else {
            this.sourceImgH = "assets/img/Bra_SEGUROS_RGB_RED_H.svg"
        }
    }

    addRecaptchaScript() {
        if (window['grecaptcha'] == undefined || window['grecaptcha'] == null) {
            window['grecaptchaCallbackrfo'] = () => {
                this.captchaComponent.renderReCaptcha();
            }
        } else {
            setTimeout(() => {
                this.captchaComponent.renderReCaptcha();
            }, 100);
        }

        (function (d, s, id, obj) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallbackrfo&render=explicit";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk-fa', this));
    }

    goToHome() {
        this.router.navigate(['/']);
    }

    openUrl(event, url) {
        if (event.key == "Enter") {
            window.location.href = url;
        }
    }

    keyGoHome(event) {
        if (event.key == 'Enter')
            this.goToHome();
    }
    goToLogin() {
        this.router.navigate(['/login']);
    }

    nextStep() {
        $('#formForgotPass').css('display', 'flex');
        $('#msgSuccess').css('display', 'none');
    }
    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: "smooth" });
    }

    submitForgotPassword() {
        var document: string;
        var email: string;
        var emailConfirm: string;
        var recaptcha: any;

        document = $('#forgotPasswordCPF').val().trim();
        email = $('#forgotPasswordEmail').val().trim();
        emailConfirm = $('#forgotPasswordEmailConfirm').val().trim();
        recaptcha = this.captchaComponent.getCaptcha();
        if (document == '') {
            this.messageService.displayValidationMessage('Preencha o CPF');
        } else if (document.length < 14) {
            this.messageService.displayValidationMessage('CPF Inválido');
        } else if (email == '') {
            this.messageService.displayValidationMessage('Preencha o E-mail');
        } else if (emailConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de E-mail');
        } else if (email != emailConfirm) {
            this.messageService.displayValidationMessage('O e-mail e o campo de confirmação devem ser iguais');
        } else if (recaptcha == null || recaptcha == undefined ||
            recaptcha == '') {
            this.messageService.displayValidationMessage('Preencha o Recaptcha');
        } else {
            this.securityService.recoveryPassword({
                document: document,
                email: email,
                emailConfirm: emailConfirm,
                captcha: recaptcha
            }).pipe().subscribe((result) => {
                if (result.warningLevel == WarningLevel.Success) {
                    $('#formFirstStep').css('display', 'none');
                    $('#formSecondStep').css('display', 'block');
                    $('#formThirdStep').css('display', 'none');
                    $('#formForgotPass').css('display', 'none');
                    $('#msgSuccess').css('display', 'block');
                } else {
                    this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });
                }
            })
        }
    }

    changePassword() {
        var email: string;
        var token: string;
        var password: string;
        var passwordConfirm: string;

        email = $('#forgotPasswordEmail').val().trim();
        token = $('#forgotPasswordToken').val().trim();
        password = $('#forgotPasswordPassword').val();
        passwordConfirm = $('#forgotPasswordPasswordConfirm').val();

        if (token == '') {
            this.messageService.displayValidationMessage('Preencha o Token');
        } else if (password == '') {
            this.messageService.displayValidationMessage('Preencha a senha');
        } else if (passwordConfirm == '') {
            this.messageService.displayValidationMessage('Preencha a Confirmação de Senha');
        } else if (password != passwordConfirm) {
            this.messageService.displayValidationMessage('A senha e o campo de confirmação devem ser iguais');
        } else {
            this.securityService.sendPasswordChangeToken({
                document: '',
                email: email,
                emailConfirm: '',
                token: token,
                password: password,
                passwordConfirm: passwordConfirm,
                captcha: ''
            }).pipe().subscribe((result) => {
                if (result.warningLevel == WarningLevel.Success) {
                    $('#formFirstStep').css('display', 'none');
                    $('#formSecondStep').css('display', 'none');
                    $('#formThirdStep').css('display', 'block');
                    $('.footer-login').css('position', 'fixed');
                    $('.footer-login').css('bottom', '0');
                } else {
                    this.messageService.displayMessage({ warninglevel: result.warningLevel, message: result.message });

                }
            });
        }
    }
}